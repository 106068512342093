


















































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { CalendarViewConfiguration } from '@/models/interfaces';
import { AMBER_STATUS, PROCESS_STATUS, RED_STATUS } from '@/config/process-statuses';
import { eachDayOfInterval } from 'date-fns'
import { format } from '@enerlytics/time-helper/dist/date-fns';

export type CalendarUnitClickPayload = {
    day: string;
    unitName: string;
    unitSid: number;
};

@Component({})
export default class CalendarListViewComponent extends Vue {
    private readonly CLICKABLE_STATUSES = [RED_STATUS, AMBER_STATUS];

    /**
     * This prop is used to know the configuration for the calendar view.
     */
    @Prop({ required: true })
    private config!: CalendarViewConfiguration

    private get days(): string[] {
        return this.config.items?.[0].days.map(e => e.day) ?? [];
    }

    private get daysInDateRange(): string[] {
        if (this.days.length === 0) {
            return [];
        }

        const firstDate = new Date(this.days[0]) ?? new Date();
        const lastDate = new Date(this.days[this.days.length - 1]) ?? new Date();

        return eachDayOfInterval({
            start: firstDate,
            end: lastDate
        }).map(date => format(date, 'd'));
    }

    private unitClicked(statusColor: number, payload: CalendarUnitClickPayload): void {
        if (!this.CLICKABLE_STATUSES.includes(statusColor)) {
            return;
        }

        this.$emit('click:unit', payload);
    }

    private getStyleForContentCell(statusColor: number): Record<string, string> {
        if (!PROCESS_STATUS[statusColor]) {
            return {};
        }

        const style = {
            backgroundColor: PROCESS_STATUS[statusColor].color,
        };

        if (this.CLICKABLE_STATUSES.includes(statusColor)) {
            style['cursor'] = 'pointer';
        }

        return style;
    }
}
