







import { Component } from 'vue-property-decorator';
import { NavbarLink, PuiNavigationToolbarClickEvent } from '@/models/pebble-ui';
import {
    COMPLETENESS_CHECK_PATH,
    MONITOR__DETAILS_PATH,
    MONITOR_PATH,
    REPORTS_PATH,
    SETTINGS_PATH
} from '@/config/router-config';
import { mixins } from 'vue-class-component';
import { AccessControlled } from '@/mixins/access-controlled';
import { Permission } from '@/config/permissions';

@Component({})
export default class NavbarComponent extends mixins(AccessControlled) {
    private get navBarLinks(): NavbarLink[] {
        const optionalLinks: NavbarLink[] = [];

        if (this.hasPermission(Permission.ADMIN)) {
            optionalLinks.push({
                displayName: this.$t('settings'),
                href: SETTINGS_PATH,
                isActive: this.isCurrentPath(SETTINGS_PATH),
            });
        }

        return [
            {
                displayName: this.$t('monitor'),
                href: MONITOR_PATH,
                isActive: this.isCurrentPath(MONITOR_PATH) || this.isCurrentPath(MONITOR__DETAILS_PATH),
            },
            {
                displayName: this.$t('completenessCheck'),
                href: COMPLETENESS_CHECK_PATH,
                isActive: this.isCurrentPath(COMPLETENESS_CHECK_PATH),
            },
            {
                displayName: this.$t('reports'),
                href: REPORTS_PATH,
                isActive: this.isCurrentPath(REPORTS_PATH),
            },
            ...optionalLinks,
        ];
    }

    private isCurrentPath(path: string): boolean {
        return this.$route.path.includes(path);
    }

    private handleClick(event: PuiNavigationToolbarClickEvent): void {
        if (!this.isCurrentPath(event.href)) {
            this.$router.push(event.href);
        }
    }
}
