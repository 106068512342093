



















import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { Comment } from '@/models/interfaces';
import Component from 'vue-class-component'

@Component({})
export default class CompletenessCheckModalCommentComponent extends Vue {
    /*
     * Prop used to provide the comment to render.
     */
    @Prop({ required: true })
    private commentData!: Comment;

    private modifiedByUserName: string | null = null;
    private taggedUserNames: string | null = null;

    private get modifiedBy(): string {
        return this.modifiedByUserName ? this.modifiedByUserName : (this.commentData.modifiedBy ?? '');
    }

    private get taggedUsers(): string {
        if (this.taggedUserNames) {
            return this.taggedUserNames;
        }

        if (this.commentData.taggedUser.length === 1) {
            return this.commentData.taggedUser[0];
        }

        return this.commentData.taggedUser.reduce(
            (previousValue, currentValue) => previousValue + ', ' + currentValue,
            ''
        );
    }

    mounted(): void {
        if (this.commentData.modifiedBy) {
            this.$store.dispatch('comments/getUserByKid', { userKid: this.commentData.modifiedBy })
                .then((userData) => {
                    this.modifiedByUserName = userData.name;
                });
        }

        if (this.commentData.taggedUser.length > 0) {
            const promises = this.commentData.taggedUser.map(userKid => this.$store.dispatch('comments/getUserByKid', { userKid }));
            Promise.all(promises).then((userDataArray) => {
                const userDataNames = userDataArray.map(e => e.name);

                if (userDataNames.length === 1) {
                    this.taggedUserNames = userDataNames[0];
                    return;
                }

                this.taggedUserNames = userDataNames.reduce(
                    (previousValue, currentValue) => previousValue + ', ' + currentValue,
                    ''
                );
            });
        }
    }
}
