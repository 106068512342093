import { CancelToken } from 'axios';
import {
    DAY_AHEAD_LABEL,
    COUNTED_LABEL,
    MEASURED_LABEL,
    AUXILIARY_LABEL,
    MASTER_DATA_LABEL
} from '@/config/processes';

export interface MonitorDataParams {
    fromDate: string;
    toDate: string;
    cancelToken: CancelToken;
}

export interface MonitorDataResponse {
    processes: Process[];
}

export interface Process {
    processType: number;
    processValue: {
        subProcesses: SubProcess[];
    };
}

export interface SubProcess {
    subProcessType: number;
    subProcessValue: SubProcessValue;
}

export interface SubProcessValue {
    history: number;
    current: number;
    date: string;
}

export interface ProcessTableValue {
    process: string;
    [DAY_AHEAD_LABEL]: SubProcessValue;
    [COUNTED_LABEL]: SubProcessValue;
    [MEASURED_LABEL]: SubProcessValue;
    [AUXILIARY_LABEL]: SubProcessValue;
    [MASTER_DATA_LABEL]: SubProcessValue;
}

export enum OverviewValueType {
    HISTORIC = 1,
    CURRENT = 2,
}

export interface MonitorDetailsDataParams {
    processType: number;
    status: number[];
    versionTimeSort: number;
    subProcessType: number;
    overviewValueType: OverviewValueType;
    itemsPerPage: number;
    pageNo: number;
    searchText: string;
    fromDate: string;
    toDate: string;
}

export interface MonitorDetailsResponse {
    monitorDetails: MonitorDetails[];
    total: number;
}

export interface MonitorDetails {
    data: Record<string, any>;
    detailId: Record<string, any>;
    markAsRead: boolean;
    markAsReadBy: string | null;
    source: string | null;
    process: number | null;
    status: number;
    versionTime: string;
    message: string | null;
    comment: Comment;
    resultRyg: string;
    resultRygOverride: string;
}

export interface MonitorDetailsData {
    message?: string;
    markAsRead?: boolean;
}

export interface MarkAsReadPayload {
    itemsToMarkAsRead: {
        markAsRead: boolean;
        detailId: Record<string, any>;
        markAsReadBy: string | null;
        resultRyg: string;
        resultRygOverride?: string;
    }[];
    process: number;
    subProcess: number;
    overviewValueType: number;
    fromDate: string;
    toDate: string;
}

export interface Comment {
    createdAt: string;
    modifiedAt: string | null;
    modifiedBy: string | null;
    taggedUser: string[];
    text: string;
}

export interface LegendDetails {
    color: string;
    label: string;
}

export interface ProcessDetailsTableValue extends Omit<MonitorDetails, 'process'> {
    process: string;
    data: MonitorDetailsData;
}

export interface ReportsExcelParams {
    fromDate: string;
    toDate: string;
    subProcess: number[];
    process: number[];
}

export interface FileResponse {
    name: string;
    content: Blob;
}

// TODO: Remove after adding pui-lightbox typings
export type VueWithRefs = (Vue | Element | Vue[] | Element[]) & {
    click: () => void;
    open: () => void;
    close: () => void;
};

export interface FormData<T = {}> {
    [key: string]: FormDataItem<T>;
}

export type FormDataItem<T = {}> = {
    label?: string;
    placeholder?: string;
    value: string;
    defaultValue: string | boolean;
    isRequired?: boolean;
    isValid: boolean;
    readonly?: boolean;
    validator?: (arg0: string) => boolean;
} & T;

export const ThresholdFormDataValueKeys = ['lowerThreshold', 'upperThreshold', 'validFrom', 'validTo'] as const;

export type ThresholdFormDataItemValuesObject<T = string> = {
    [key in typeof ThresholdFormDataValueKeys[number]]: T;
}

export type ThresholdFormDataValidationFn = (input: string) => string | undefined;

export type ThresholdFormData = {
    labels: ThresholdFormDataItemValuesObject;
    initialValues: ThresholdFormDataItemValuesObject;
    values: ThresholdFormDataItemValuesObject;
    validation: ThresholdFormDataItemValuesObject<boolean>;
    validators: ThresholdFormDataItemValuesObject<ThresholdFormDataValidationFn>;
    errorMessages: ThresholdFormDataItemValuesObject<string | undefined>;
};

export enum ThresholdModalMode {
    ADD = 1,
    EDIT = 2
}

export type ThresholdFormConfig = {
    [key in ThresholdModalMode]: {
        titleLabel: string;
        submitFn: () => Promise<void>;
    };
};

export type EmailSettingsId = Record<any, any>;

export interface EmailSettingsItem {
    emailSettingsId: EmailSettingsId;
    process: number;
    subProcess: number;
    color: number;
    email: string;
}

export interface ListEmailSettingsResponse {
    emailSettings: EmailSettingsItem[];
}

export interface EmailTemplateTagItem {
    txtModuleSid: number;
    txtModule: string;
    insertTs: string;
}

export interface GetEmailTemplateTagsResponse {
    emailSettingsTags: EmailTemplateTagItem[];
    emailCommentsTags: EmailTemplateTagItem[];
}

export interface EmailTemplateItem {
    emailSettingsId: EmailSettingsId;
    email: string;
    subject: string;
    template: string;
}

export interface GetEmailTemplatePayload {
    emailSettingsId: EmailSettingsId;
}

export type AddOrUpdateEmailTemplatePayload = EmailTemplateItem;

export interface AddOrUpdateEmailCommentTemplatePayload {
    subject: string;
    template: string;
}

export interface CompletenessCheckParams {
    countries: string[];
    units: number[];
    fromDate: string;
    toDate: string;
    subProcess: number;
}

export type CompletenessCheckResponse = CompletenessCheckCountResponse & {
    units: CompletenessCheckUnit[];
}

export interface CompletenessCheckUnit {
    unitName: string;
    unitSid: number;
    days: CompletenessCheckUnitDayItem[];
}

export interface CompletenessCheckUnitDayItem {
    day: string;
    statusColor: number;
}

export type GetCompletenessCheckDetailItemPayload = GetCompletenessCheckDetailItemFiltersPayload & {
    unit: number;
    fromDate: string;
    subProcessType: number;
};

export interface GetCompletenessCheckDetailItemFiltersPayload {
    valueIdSearchText?: string;
    itemsPerPage?: number;
    pageNo?: number;
}

export interface CompletenessCheckDetailsResponse {
    completenessCheckDetailsDtos: CompletenessCheckDetailItem[];
}

export interface CompletenessCheckDetailItem {
    valueIdentification: string;
    hourlies: CompletenessCheckDetailItemHourly[];
    comments: Comment[];
}

export interface CompletenessCheckDetailItemHourly {
    color: number;
    hour: number;
    minutesValues?: {
        color: string;
        minute: number;
    }[];
}

export interface CalendarViewConfiguration {
    range: {
        start: Date;
        end: Date;
    };
    items: CompletenessCheckUnit[];
}

export interface CompletenessCheckCountResponse {
    greenCount: number;
    redCount: number;
    yellowCount: number;
}

export interface CompletenessCheckFilterOptionsResponse {
    countriesWithUnits: CompletenessCheckFilterOptions;
}

export type CompletenessCheckFilterOptions = {
    countryId: number;
    countryName: string;
    units: {
        unitSid: number;
        unitName: string;
    }[];
}[];

export interface UnitFilterOption {
    displayName: string;
    value: number;
    countryId: number;
}

export interface PostCommentPayload {
    detailId: Record<string, any>;
    modifiedBy: string;
    overrideComment: string;
    taggedUser: string[];
}

export interface CommentsTabDefaultField<T = string> {
    label: string;
    placeholder?: string;
    value: T;
    isValid: boolean;
    isRequired: boolean;
}

export interface CommentsTabFields {
    comment: CommentsTabDefaultField;
    people: CommentsTabDefaultField<string[]>;
}

export interface Error {
    type: string;
    title: string;
    text: string;
}

export interface SelectOption {
    label: string;
    value: string;
}

export interface CachedUser {
    name: string;
    userKid: string;
}

export type PayloadWithSignal<T = {}> = T & { signal: AbortSignal };
