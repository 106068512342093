import { Module } from 'vuex';
import { Permission } from '@/config/permissions';
import { ExtendedUserPermissions } from '@/models/uam';

export type UserInformation = {
    kid: string;
    name: string;
    email: string;
};

interface UserState {
    userInformation: UserInformation;
    permissions: Permission[];
    hasLoadedProfile: boolean;
}

const state: UserState = {
    userInformation: {
        kid: '',
        name: '',
        email: '',
    },
    permissions: [],
    hasLoadedProfile: false,
};

const SET_PERMISSIONS_DATA = 'setPermissions';
const SET_PROFILE_LOADED = 'setProfileLoaded';
const SET_USER_INFORMATION = 'setUserInformation';

const UserModule: Module<UserState, any> = {
    state,

    mutations: {
        [SET_PERMISSIONS_DATA](state, data: Permission[]): void {
            state.permissions = data;
        },
        [SET_PROFILE_LOADED](state): void {
            state.hasLoadedProfile = true;
        },
        [SET_USER_INFORMATION](state, data: UserInformation): void {
            state.userInformation = data;
        },
    },

    actions: {
        setProfile({ commit }, profile: ExtendedUserPermissions): void {
            if (profile.permissions) {
                commit(SET_PERMISSIONS_DATA, profile.permissions.map(p => p.name));
            }

            commit(SET_USER_INFORMATION, {
                kid: profile.kid,
                name: profile.name,
                email: profile.email,
            });

            commit(SET_PROFILE_LOADED);
        }
    },

    getters: {
        getUserPermissions(state): Permission[] {
            return state.permissions;
        },
        hasLoadedProfile(state): boolean {
            return state.hasLoadedProfile;
        },
        getUserInformation(state): UserInformation {
            return state.userInformation;
        },
    },

    namespaced: true,
};

export default UserModule;
