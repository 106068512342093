import Vue from 'vue';
import Vuex from 'vuex';
import UserModule from './modules/user.module';
import ProcessModule from './modules/process.module';
import RefreshModule from './modules/refresh.module';
import ReportsModule from './modules/reports.module';
import SettingsModule from './modules/settings.module';
import CompletenessModule from './modules/completeness.module';
import CommentsModule from './modules/comments.module';
import { DEFAULT_LOCALE } from '@/config/consts';
import { loadLanguageAsync } from '@/utils/i18n';
import ThresholdsModule from '@/store/modules/thresholds.module';

Vue.use(Vuex);

const applicationState: {
    isLoading: boolean;
    lang: string;
} = {
    isLoading: false,
    lang: DEFAULT_LOCALE,
};

export default new Vuex.Store({
    state: applicationState,
    mutations: {
        loading(state): void {
            state.isLoading = !state.isLoading;
        },
    },
    actions: {
        async setLang({ state }, lang: string): Promise<string | void> {
            await loadLanguageAsync(lang);
            state.lang = lang
        },
    },
    modules: {
        user: UserModule,
        process: ProcessModule,
        refresh: RefreshModule,
        reports: ReportsModule,
        settings: SettingsModule,
        completeness: CompletenessModule,
        comments: CommentsModule,
        thresholds: ThresholdsModule,
    },
    getters: {
        isLoading(state): boolean {
            return state.isLoading;
        },
        lang(state): string {
            return state.lang;
        },
    },
});
