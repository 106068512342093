
















import Component from 'vue-class-component';
import Vue from 'vue';
import { Comment, CompletenessCheckDetailItem } from '@/models/interfaces';
import CompletenessCheckModalComment
    from '@/components/completeness-check/completeness-check-details-modal/completeness-check-modal-comments-tab/completeness-check-modal-comment/completeness-check-modal-comment.vue';

@Component({
    components: {
        CompletenessCheckModalComment,
    }
})
export default class CompletenessCheckModalCommentsTabComponent extends Vue {
    private get comments(): Comment[] {
        return this.$store.getters['completeness/getDetailItems']
            .flatMap((e: CompletenessCheckDetailItem) => e.comments);
    }
}
