import { Module } from 'vuex';
import {
    AddOrUpdateEmailCommentTemplatePayload,
    AddOrUpdateEmailTemplatePayload,
    EmailSettingsItem, EmailTemplateItem,
    EmailTemplateTagItem, GetEmailTemplatePayload, PayloadWithSignal
} from '@/models/interfaces';
import { RED_STATUS } from '@/config/process-statuses';
import { DATA_SUPPLY } from '@/config/processes';
import { EmailSettingsService } from '@/services/email-settings-service';

const emailSettingsService = new EmailSettingsService();

export interface EmailTemplateSectionIdentifier {
    color: number;
    process: number;
}

const state: SettingsState = {
    emailSettings: [],
    emailTemplateTags: [],
    emailCommentsTags: [],
    emailTemplate: null,
    emailCommentsTemplate: null,
    openedEmailTemplateSection: {
        color: RED_STATUS,
        process: DATA_SUPPLY,
    },
};

interface SettingsState {
    emailSettings: EmailSettingsItem[];
    emailTemplateTags: EmailTemplateTagItem[];
    emailCommentsTags: EmailTemplateTagItem[];
    emailTemplate: EmailTemplateItem | null;
    emailCommentsTemplate: EmailTemplateItem | null;
    openedEmailTemplateSection: EmailTemplateSectionIdentifier;
}

const SET_EMAIL_SETTINGS = 'setEmailSettings';
const RESET_EMAIL_SETTINGS = 'resetEmailSettings';
const SET_EMAIL_TEMPLATE_TAGS = 'setEmailTemplateTags';
const RESET_EMAIL_TEMPLATE_TAGS = 'resetEmailTemplateTags';
const SET_EMAIL_COMMENTS_TAGS = 'setEmailCommentsTags';
const RESET_EMAIL_COMMENTS_TAGS = 'resetEmailCommentsTags';
const SET_EMAIL_TEMPLATE = 'setEmailTemplate';
const RESET_EMAIL_TEMPLATE = 'resetEmailTemplate';
const SET_EMAIL_COMMENTS_TEMPLATE = 'setEmailCommentsTemplate';
const RESET_EMAIL_COMMENTS_TEMPLATE = 'resetEmailCommentsTemplate';
const SET_OPENED_EMAIL_TEMPLATE_SECTION = 'setOpenedEmailTemplateSection';

const SettingsModule: Module<SettingsState, any> = {
    state: state,

    mutations: {
        [SET_EMAIL_SETTINGS](state, emailSettings: EmailSettingsItem[]): void {
            state.emailSettings = emailSettings;
        },
        [RESET_EMAIL_SETTINGS](state): void {
            state.emailSettings = [];
        },
        [SET_EMAIL_TEMPLATE_TAGS](state, emailTemplateTags: EmailTemplateTagItem[]): void {
            state.emailTemplateTags = emailTemplateTags;
        },
        [RESET_EMAIL_TEMPLATE_TAGS](state): void {
            state.emailTemplateTags = [];
        },
        [SET_EMAIL_COMMENTS_TAGS](state, emailCommentsTags: EmailTemplateTagItem[]): void {
            state.emailCommentsTags = emailCommentsTags;
        },
        [RESET_EMAIL_COMMENTS_TAGS](state): void {
            state.emailCommentsTags = [];
        },
        [SET_EMAIL_TEMPLATE](state, emailTemplate: EmailTemplateItem): void {
            state.emailTemplate = emailTemplate;
        },
        [RESET_EMAIL_TEMPLATE](state): void {
            state.emailTemplate = null;
        },
        [SET_EMAIL_COMMENTS_TEMPLATE](state, emailCommentsTemplate: EmailTemplateItem): void {
            state.emailCommentsTemplate = emailCommentsTemplate;
        },
        [RESET_EMAIL_COMMENTS_TEMPLATE](state): void {
            state.emailCommentsTemplate = null;
        },
        [SET_OPENED_EMAIL_TEMPLATE_SECTION](state, { color, process }: EmailTemplateSectionIdentifier): void {
            state.openedEmailTemplateSection.color = color;
            state.openedEmailTemplateSection.process = process;
        }
    },

    actions: {
        async fetchEmailSettings({ commit }, signal: AbortSignal): Promise<void> {
            try {
                const response = await emailSettingsService.fetchEmailSettings(signal);
                commit(SET_EMAIL_SETTINGS, response.result.emailSettings);
            } catch (e) {
                commit(RESET_EMAIL_SETTINGS);
                if (!signal.aborted) {
                    throw e;
                }
            }
        },
        async fetchEmailTags({ commit }, signal: AbortSignal): Promise<void> {
            try {
                const response = await emailSettingsService.fetchEmailTags(signal);
                commit(SET_EMAIL_TEMPLATE_TAGS, response.result.emailSettingsTags)
                commit(SET_EMAIL_COMMENTS_TAGS, response.result.emailCommentsTags)
            } catch (e) {
                commit(RESET_EMAIL_TEMPLATE_TAGS);
                commit(RESET_EMAIL_COMMENTS_TAGS);
                if (!signal.aborted) {
                    throw e;
                }
            }
        },
        async fetchEmailTemplate({ commit }, { signal, ...payload }: PayloadWithSignal<GetEmailTemplatePayload>): Promise<void> {
            try {
                const response = await emailSettingsService.fetchEmailTemplate(payload);
                commit(SET_EMAIL_TEMPLATE, response.result);
            } catch (e) {
                commit(RESET_EMAIL_TEMPLATE);
                if (!signal.aborted) {
                    throw e;
                }
            }
        },
        async updateEmailTemplate(context, payload: AddOrUpdateEmailTemplatePayload): Promise<void> {
            await emailSettingsService.updateEmailTemplate(payload);
        },
        async fetchEmailCommentsTemplate({ commit }, { signal }: PayloadWithSignal<GetEmailTemplatePayload>): Promise<void> {
            try {
                const response = await emailSettingsService.fetchEmailCommentsTemplate(signal);
                commit(SET_EMAIL_COMMENTS_TEMPLATE, response.result);
            } catch (e) {
                commit(RESET_EMAIL_COMMENTS_TEMPLATE);
                if (!signal.aborted) {
                    throw e;
                }
            }
        },
        async updateEmailCommentsTemplate(context, payload: AddOrUpdateEmailCommentTemplatePayload): Promise<void> {
            await emailSettingsService.updateEmailCommentsTemplate(payload);
        },
        async setOpenedEmailTemplateSection({ commit }, payload: EmailTemplateSectionIdentifier): Promise<void> {
            commit(SET_OPENED_EMAIL_TEMPLATE_SECTION, payload);
        }
    },

    getters: {
        getEmailSettings(state): EmailSettingsItem[] {
            return state.emailSettings;
        },
        getEmailTemplateTags(state): EmailTemplateTagItem[] {
            return state.emailTemplateTags;
        },
        getEmailCommentsTags(state): EmailTemplateTagItem[] {
            return state.emailCommentsTags;
        },
        getEmailTemplate(state): EmailTemplateItem | null {
            return state.emailTemplate;
        },
        getEmailCommentsTemplate(state): EmailTemplateItem | null {
            return state.emailCommentsTemplate;
        },
        getOpenedEmailTemplateSection(state): EmailTemplateSectionIdentifier {
            return state.openedEmailTemplateSection;
        },
    },

    namespaced: true
}

export default SettingsModule;
