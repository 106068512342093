














import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import { LocaleMessage } from 'vue-i18n';

@Component({})
export default class TableSearchComponent extends Vue {
    @Prop({ default: () => '' })
    private placeholderText!: string | LocaleMessage;

    private searchTerm = '';

    @Emit()
    filterByKeyword(): string {
        return this.searchTerm;
    }
}
