import { ApiResponse, Application, ApiResponseList } from '@/models';
import Vue from 'vue';

/**
 * @name AppService
 * @description This class is used to query the identity server for user related data,
 * which is not related to authentication.
 */
export class AppService {
    public async getApps(): Promise<ApiResponse<ApiResponseList<Application>>> {
        return (await Vue.prototype.$sdk.$http?.$core.metaData.request.api.get('/use-cases')).data;
    }
}
