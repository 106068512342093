
























import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import { GREY_STATUS, PROCESS_STATUS } from '@/config/process-statuses';
import { CompletenessCheckDetailItemHourly } from '@/models/interfaces';
import { numberCompareAscFn } from '@/utils/comparison-utils';
import { set } from 'date-fns';
import { format } from '@enerlytics/time-helper/dist/date-fns';

@Component({})
export default class CompletenessCheckModalDetailsTableCellComponent extends Vue {
    private readonly PROCESS_STATUS = PROCESS_STATUS;

    /*
     * This prop is used to know the hourly data to display for this cell.
     */
    @Prop({ required: true })
    private hourlyData!: CompletenessCheckDetailItemHourly;

    /*
     * This prop is used to know the left/right positioning of the tooltip.
     */
    @Prop({ default: () => 'right' })
    private positioning!: 'left' | 'right';

    private get shouldDisplayCell(): boolean {
        return this.hourlyData.color !== GREY_STATUS;
    }

    private get colorStyle(): Record<any, any> {
        return {
            backgroundColor: this.PROCESS_STATUS[this.hourlyData.color].color,
        };
    }

    private get tooltipLines(): string[] {
        if (!this.hourlyData.minutesValues) {
            return [];
        }

        const lines: string[] = [];

        lines.push(`${this.$t('completenessCheckDetailModal.timestamp')}:`);

        this.hourlyData.minutesValues.sort((e1, e2) => numberCompareAscFn(e1.minute, e2.minute));
        this.hourlyData.minutesValues.forEach(e => {
            const date = set(new Date(), {
                hours: this.hourlyData.hour,
                minutes: e.minute,
            });

            const formattedTime = format(date, 'HH:mm');
            const colorName = this.$t(PROCESS_STATUS[e.color].statusColorName);

            lines.push(`${formattedTime} - ${colorName}`);
        });

        return lines;
    }
}
