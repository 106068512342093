import BaseService from '@/utils/base-service';
import { CancelToken } from 'axios';
import { ApiResponse } from '@/models';
import { GetMonitorOverviewResponse } from '@/models/services/monitor-overview';

/**
 * @name MonitorOverviewService
 * @description This class is used to communicate with the GDC service for
 * information related to Monitor Overview.
 */
export class MonitorOverviewService extends BaseService {
    constructor() {
        super('monitor-overview');
    }

    public async fetchProcesses(fromDate: string, toDate: string, cancelToken?: CancelToken): Promise<ApiResponse<GetMonitorOverviewResponse>> {
        return (await this.api.get<ApiResponse<GetMonitorOverviewResponse>>(`${this.resourcePath}/${fromDate}/${toDate}`, { cancelToken })).data;
    }
}
