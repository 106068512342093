import BaseService from '@/utils/base-service';
import { FileResponse, ReportsExcelParams } from '@/models/interfaces';

/**
 * @name ReportsService
 * @description This class is used to communicate with the GDC service for
 * information related to reports.
 */
export class ReportsService extends BaseService {
    constructor() {
        super('report-export');
    }

    public async exportReport(params: ReportsExcelParams, signal?: AbortSignal): Promise<FileResponse> {
        const response = await this.api.post<Blob>(this.resourcePath, params, { signal, responseType: 'blob' });
        const name = response.headers['content-disposition'].split('UTF-8\'\'')[1];

        return {
            name,
            content: response.data
        };
    }
}
