import BaseService from '@/utils/base-service';
import {
    CompletenessCheckFilterOptionsResponse,
    CompletenessCheckParams,
    CompletenessCheckResponse
} from '@/models/interfaces';
import { ApiResponse } from '@/models';

/**
 * @name CompletenessCheckService
 * @description This class is used to communicate with the GDC service for
 * information related to Completeness Check.
 */
export class CompletenessCheckService extends BaseService {
    constructor() {
        super('completeness-check');
    }

    public async fetchCompletenessCheckItems(params: CompletenessCheckParams, signal?: AbortSignal): Promise<ApiResponse<CompletenessCheckResponse>> {
        return (await this.api.post<ApiResponse<CompletenessCheckResponse>>(`${this.resourcePath}/overview`, params, { signal })).data;
    }

    public async fetchCompletenessCheckFilterItems(): Promise<ApiResponse<CompletenessCheckFilterOptionsResponse>> {
        return (await this.api.get<ApiResponse<CompletenessCheckFilterOptionsResponse>>(`${this.resourcePath}/filter`)).data;
    }
}
