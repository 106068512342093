const emailValidationRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const notNullOrEmptyValidationFn = (input: string): boolean => input !== null && input.trim() !== '';

export const emailValidationFn = (input: string): boolean => {
    if (!notNullOrEmptyValidationFn(input)) {
        return false;
    }

    return !!input.toLowerCase().match(emailValidationRegex);
}
