import { NavigationGuard, RouteRecord } from 'vue-router';
import store from '@/store';
import { Permission } from '@/config/permissions';

const ensureProfileIsLoaded = async (): Promise<void> => {
    if (store.getters['user/hasLoadedProfile']) {
        return;
    }

    return new Promise((resolve) => {
        const unwatch = store.watch(
            (state, getters) => getters['user/hasLoadedProfile'],
            (hasLoadedProfile) => {
                if (hasLoadedProfile) {
                    unwatch();
                    resolve();
                }
            }
        )
    });
}

const checkIfRouteRecordHasSomePermission = (routeRecord: RouteRecord, userPermissions: Permission[]): boolean => {
    return routeRecord.meta.requiresSomePermissions?.some((permission: Permission) => userPermissions.includes(permission)) ?? true;
}

const checkIfRouteRecordHasAllPermissions = (routeRecord: RouteRecord, userPermissions: Permission[]): boolean => {
    return routeRecord.meta.requiresAllPermissions?.some((permission: Permission) => userPermissions.includes(permission)) ?? true;
}

export const authenticationGuard: NavigationGuard = async (to, from, next) => {
    const protectedSomePermissionRoutes = to.matched.filter(record => record.meta.requiresSomePermission);
    const protectedAllPermissionRoutes = to.matched.filter(record => record.meta.requiresAllPermissions);

    if (protectedSomePermissionRoutes.length === 0 && protectedAllPermissionRoutes.length === 0) {
        next();
        return;
    }

    await ensureProfileIsLoaded();
    const userPermissions = store.getters['user/getUserPermissions'];

    const isAllowed = protectedSomePermissionRoutes.every(record => checkIfRouteRecordHasSomePermission(record, userPermissions))
        && protectedAllPermissionRoutes.every(record => checkIfRouteRecordHasAllPermissions(record, userPermissions));

    if (isAllowed) {
        next();
    } else {
        next({
            name: 'Forbidden'
        });
    }
}



