import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import MonitorComponent from '@/components/monitor/monitor.vue';
import CompletenessCheckComponent from '@/components/completeness-check/completeness-check.vue';
import ReportsComponent from '@/components/reports/reports.vue';
import MonitorDetailsComponent from '@/components/monitor-details/monitor-details.vue';
import SettingsComponent from '@/components/settings/settings.vue';
import {
    COMPLETENESS_CHECK_PATH,
    MONITOR_PATH,
    REPORTS_PATH,
    MONITOR__DETAILS_PATH,
    SETTINGS_PATH,
    FORBIDDEN_PATH
} from '@/config/router-config';
import { authenticationGuard } from '@/router/authentication-guard';
import { Permission } from '@/config/permissions';
import ForbiddenPageComponent from '@/components/forbidden-page/forbidden-page.vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
    {
        path: '/',
        redirect: MONITOR_PATH
    },
    {
        path: MONITOR_PATH,
        name: 'Monitor',
        component: MonitorComponent,
    },
    {
        path: COMPLETENESS_CHECK_PATH,
        name: 'Completeness check',
        component: CompletenessCheckComponent,
    },
    {
        path: REPORTS_PATH,
        name: 'Reports',
        component: ReportsComponent,
    },
    {
        path: `${MONITOR__DETAILS_PATH}/:process/:subProces/:overviewValueType/:fromDate/:toDate`,
        name: 'Monitor details',
        component: MonitorDetailsComponent,
        props: true
    },
    {
        path: SETTINGS_PATH,
        name: 'Settings',
        component: SettingsComponent,
        meta: {
            requiresAllPermissions: [
                Permission.ADMIN
            ],
        },
    },
    {
        path: FORBIDDEN_PATH,
        name: 'Forbidden',
        component: ForbiddenPageComponent,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeResolve(authenticationGuard);

export default router;
