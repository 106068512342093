




















import Component from 'vue-class-component';
import Vue from 'vue';
import ThresholdsTab from '@/components/settings/thresholds-tab/thresholds-tab.vue';
import EmailSettingsTab from '@/components/settings/email-settings-tab/email-settings-tab.vue';

@Component({
    components: {
        ThresholdsTab,
        EmailSettingsTab,
    },
})
export default class SettingsComponent extends Vue {
    private selectedTabIndex = 0;
    private tabs = [this.$t('threshold'), this.$t('emailSettings')];

    private onTabChanged(tabIndex: number): void {
        this.selectedTabIndex = tabIndex;
    }
}
