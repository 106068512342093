























import Vue from 'vue';
import Component from 'vue-class-component';
import { PuiTableVendorOptions, PuiTableVendorOptionsCellClasses } from '@/models/pebble-ui';
import { Prop } from 'vue-property-decorator';
import { ThresholdItem } from '@/models/services/thresholds';
import { format } from '@enerlytics/time-helper/dist/date-fns';
import { isWithinInterval } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

type TableData = ThresholdItem & {
    highlighted: boolean;
};

@Component({
    filters: {
        formatDate(input: string): string {
            return format(utcToZonedTime(input, 'UTC'), 'dd/MM/yyyy');
        },
    }
})
export default class ThresholdsTableComponent extends Vue {
    private readonly COLUMNS = ['lowerThreshold', 'upperThreshold', 'validFrom', 'validTo', 'actions'];

    /**
     * This prop is used to know the thresholds this table should display.
     */
    @Prop({ required: true, default: () => [] })
    private thresholdItems!: ThresholdItem[];

    private headings: Record<string, string> = {};

    private get tableData(): TableData[] {
        return this.thresholdItems.map(item => ({
            ...item,
            highlighted: this.isThresholdCurrent(item),
        }));
    }

    private get cellClassesOption(): PuiTableVendorOptionsCellClasses {
        const cellClassesConfig: PuiTableVendorOptionsCellClasses = {};

        this.COLUMNS.forEach(column => {
            cellClassesConfig[column] = [
                {
                    class: 'highlighted-cell',
                    condition: (row): boolean => row.highlighted,
                }
            ]
        });

        return cellClassesConfig;
    }

    private get vendorOptions(): PuiTableVendorOptions<TableData[]> {
        return {
            options: {
                headings: this.headings,
                sortable: [],
                cellClasses: this.cellClassesOption,
            },
            columns: this.COLUMNS,
            data: this.tableData,
        };
    }

    private created(): void {
        this.COLUMNS.forEach(column => {
            this.headings[column] = this.$t(`thresholdsTab.${column}`);
        });
    }

    private isThresholdCurrent(thresholdItem: ThresholdItem): boolean {
        try {
            return isWithinInterval(new Date(), {
                start: new Date(thresholdItem.validFrom),
                end: new Date(thresholdItem.validTo)
            });
        } catch {
            return false;
        }
    }

    private editThresholdClicked(thresholdItem: any): void {
        this.$emit('click:edit-threshold', thresholdItem);
    }
}
