export const DATA_SUPPLY = 1;
export const ALIGNMENT = 2;
export const VALIDATION = 3;
export const COMPLETENESS = 4;

export const DAY_AHEAD = 1;
export const COUNTED = 2;
export const MEASURED = 3;
export const AUXILIARY = 4;
export const MASTER_DATA = 5;

export const DATA_SUPPLY_LABEL = 'dataSupply';
export const ALIGNMENT_LABEL = 'alignment';
export const VALIDATION_LABEL = 'validation';
export const COMPLETENESS_LABEL = 'completeness';

export const DAY_AHEAD_LABEL = 'dayAhead';
export const COUNTED_LABEL = 'countedValues';
export const MEASURED_LABEL = 'measuredValues';
export const AUXILIARY_LABEL = 'auxiliaryValues';
export const MASTER_DATA_LABEL = 'masterData';

export interface Processes {
    [key: number]: ProcessItem;
}

export interface ProcessItem {
    label: string;
}

export const PROCESSES = {
    [DATA_SUPPLY]: {
        label: DATA_SUPPLY_LABEL
    },
    [ALIGNMENT]: {
        label: ALIGNMENT_LABEL
    },
    [VALIDATION]: {
        label: VALIDATION_LABEL
    },
    [COMPLETENESS]: {
        label: COMPLETENESS_LABEL
    },
} as Processes;

export const SUB_PROCESSES = {
    [DAY_AHEAD]: {
        label: DAY_AHEAD_LABEL
    },
    [COUNTED]: {
        label: COUNTED_LABEL
    },
    [MEASURED]: {
        label: MEASURED_LABEL
    },
    [AUXILIARY]: {
        label: AUXILIARY_LABEL
    },
    [MASTER_DATA]: {
        label: MASTER_DATA_LABEL
    },
} as Processes;
