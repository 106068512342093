





























import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import Vue from 'vue';

@Component({})
export default class LegendItemComponent extends Vue {
    /**
     * This prop is required to know the color for the circle.
     * Valid values are valid values for background-color css property.
     */
    @Prop()
    private statusColor!: string;

    /**
     * This prop is used to display a small letter on the circle.
     */
    @Prop({ default: '' })
    private statusLabel?: string;

    /**
     * This prop is used to know the color for the label.
     * Valid values are valid values for color css property.
     */
    @Prop({ default: 'transparent' })
    private labelColor?: string;

    /**
     * This prop is used to know the font-size for the label.
     * Valid values are valid values for font-size css property.
     */
    @Prop({ default: '1rem' })
    private labelFontSize?: string;

    /**
     * This prop is used to display the legend label.
     */
    @Prop({ default: '' })
    private label?: string;

    /**
     * This prop is used to know if legend is clickable.
     */
    @Prop({ default: false })
    private clickable?: boolean;

    private get hasStatusLabel(): boolean {
        return this.statusLabel !== '';
    }

    private get hasLabel(): boolean {
        return this.label !== '';
    }

    private legendClicked(): void {
        this.$emit('legendClicked')
    }
}
