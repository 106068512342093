import { render, staticRenderFns } from "./process-overview-values-table-cell.vue?vue&type=template&id=80ff036a&scoped=true"
import script from "./process-overview-values-table-cell.vue?vue&type=script&lang=ts"
export * from "./process-overview-values-table-cell.vue?vue&type=script&lang=ts"
import style0 from "./process-overview-values-table-cell.vue?vue&type=style&index=0&id=80ff036a&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "80ff036a",
  null
  
)

export default component.exports