

























































































import Component, { mixins } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CachedUser, CommentsTabFields, MonitorDetails, SelectOption } from '@/models/interfaces';
import UamUserInput from './uam-user-input.vue';
import { AccessControlled } from '@/mixins/access-controlled';
import { Permission } from '@/config/permissions';
import { UserInformation } from '@/store/modules/user.module';

@Component({
    components: {
        UamUserInput,
    }
})
export default class CommentsTabComponent extends mixins(AccessControlled) {
    @Prop({ default: () => null })
    private commentDetails!: MonitorDetails;

    private readonly MIN_CHARACTER_COUNT = 2;

    private abortController = new AbortController();

    private isSubmitting = false;
    private isLoadingUsers = false;

    private initialUserList: SelectOption[] = [];
    private modifiedByName = this.commentDetails.comment?.modifiedBy ?? '';

    private fields: CommentsTabFields = {
        comment: {
            label: this.$t('commentLightbox.fields.comment.label'),
            placeholder: this.$t('commentLightbox.fields.comment.placeholder'),
            value: this.commentDetails.comment?.text ?? '',
            isRequired: true,
            isValid: true,
        },
        people: {
            label: this.$t('commentLightbox.fields.people.label'),
            value: this.commentDetails.comment?.taggedUser ?? [],
            isRequired: false,
            isValid: true,
        },
    };

    private get isAllowedToEditComments(): boolean {
        return this.hasSomePermission(Permission.EDITOR, Permission.ADMIN);
    }

    private get isSubmitButtonDisabled(): boolean {
        return this.isSubmitting
        || this.errorsDetected
        || this.fields.comment.value.trim() === ''
        || this.isUsersListInvalid;
    }

    private get errorsDetected(): boolean {
        return !this.fields.comment.isValid || !this.fields.people.isValid;
    }

    private get userInformation(): UserInformation {
        return this.$store.getters['user/getUserInformation'];
    }

    private get cachedUsers(): CachedUser[] {
        return this.$store.getters['comments/getCachedUsers'];
    }

    private get showTaggedUsersList(): boolean {
        return this.commentDetails.comment?.taggedUser?.length > 0;
    }

    private get taggedUsersList(): string {
        const initialValue = '';
        return this.initialUserList.reduce(
            (previousValue, currentValue) => previousValue + ' @' + currentValue.label,
            initialValue
        );
    }

    private async created(): Promise<void> {
        if (this.commentDetails.comment?.taggedUser?.length > 0) {
            this.isLoadingUsers = true;
            this.initialUserList = await Promise.all(this.commentDetails.comment.taggedUser.map(async (userKid: string): Promise<SelectOption> => {
                const label = await this.getNameByKid(userKid);
                if (label === null) {
                    this.fields.people.isValid = false;
                }
                return {
                    label,
                    value: userKid
                }
            }));
            this.isLoadingUsers = false;
        }
        if (this.commentDetails.comment?.modifiedBy) {
            this.modifiedByName = await this.getNameByKid(this.commentDetails.comment.modifiedBy);
        }
    }

    private async getNameByKid(userKid: string): Promise<string> {
        const result = this.cachedUsers.find((user: CachedUser) => user.userKid === userKid);
        if (result) {
            return result.name;
        }
        try {
            const result = await this.$store.dispatch('comments/getUserByKid', { userKid, signal: this.abortController.signal });
            if (result) {
                return result.name;
            }
            return userKid;
        } catch (e) {
            return userKid;
        }
    }

    private errorMessage(comment: string): string | undefined {
        if (comment.trim().length === 0) {
            return this.$t('commentLightbox.errorMessage.required');
        } else if (comment.trim().length < this.MIN_CHARACTER_COUNT) {
            return this.$t('commentLightbox.errorMessage.minCharacters', { minNumberOfCharacters: this.MIN_CHARACTER_COUNT });
        }
    }

    private handleValidationForField(fieldKey: keyof CommentsTabFields): void {
        if (
            (!this.fields[fieldKey].value && this.fields[fieldKey].isRequired) ||
            (fieldKey === 'comment' && this.fields.comment.value.trim().length < this.MIN_CHARACTER_COUNT) ||
            (fieldKey === 'people' && this.isUsersListInvalid)
        ) {
            this.setFieldErrorState(fieldKey);
        } else {
            this.resetFieldErrorState(fieldKey);
        }
    }

    private get isUsersListInvalid(): boolean {
        const currentUserList = this.fields.people.value;
        return (currentUserList as string[]).some((userKid) => userKid === null || userKid === undefined);
    }

    private setFieldErrorState(fieldKey: keyof CommentsTabFields): void {
        if (this.isValidFieldKey(fieldKey)) {
            this.fields[fieldKey].isValid = false;
        }
    }

    private resetFieldErrorState(fieldKey: keyof CommentsTabFields): void {
        if (this.isValidFieldKey(fieldKey)) {
            this.fields[fieldKey].isValid = true;
        }
    }

    private isValidFieldKey(fieldKey: keyof CommentsTabFields): boolean {
        return this.fields[fieldKey] !== undefined && this.fields[fieldKey].isValid !== undefined;
    }

    private updateUsers(users: SelectOption[]): void {
        this.fields.people.value = users.map(user => user.value);
        this.handleValidationForField('people');
    }

    private async handleSubmission(): Promise<void> {
        Object.keys(this.fields).forEach((fieldKey) =>
            this.handleValidationForField(fieldKey as keyof CommentsTabFields),
        );
        if (this.errorsDetected) return;

        this.isSubmitting = true;

        const { name, kid } = this.userInformation;

        const { detailId, data: { message }, status, resultRyg, resultRygOverride } = this.commentDetails;

        const params = {
            message,
            detailId,
            username: name,
            modifiedBy: kid,
            overrideComment: this.fields.comment.value.trim(),
            taggedUser: this.fields.people.value,
            resultRyg: resultRyg ?? status.toString(),
            resultRygOverride,
        };

        try {
            await this.$store.dispatch('comments/postComment', { params, signal: this.abortController.signal });
            const action = this.commentDetails.comment ? this.$t('commentLightbox.apiSuccess.action.updated') : this.$t('commentLightbox.apiSuccess.action.added');
            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('commentLightbox.apiSuccess.title'),
                copy: this.$t('commentLightbox.apiSuccess.description', { action })
            });
            this.$emit('close-lightbox', true);
        } catch (e) {
            const serverErrorMessage = `: ${(e as any)?.response?.data?.errors?.[0] ?? 'N/A'}`;
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('commentLightbox.apiError.title'),
                copy: this.$t('commentLightbox.apiError.description') + serverErrorMessage
            });
            this.isSubmitting = false;
        }
    }
}
