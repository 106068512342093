import { Module } from 'vuex';
import {
    CompletenessCheckUnit,
    CompletenessCheckParams,
    CompletenessCheckResponse,
    CompletenessCheckCountResponse,
    CompletenessCheckFilterOptionsResponse,
    CompletenessCheckFilterOptions,
    CompletenessCheckDetailItem,
    PayloadWithSignal,
    GetCompletenessCheckDetailItemPayload
} from '@/models/interfaces'
import axios from 'axios'
import Vue from 'vue';
import { CompletenessCheckService } from '@/services/completeness-check-service';
import { CompletenessCheckDetailsService } from '@/services/completeness-check-details-service';

const completenessCheckService = new CompletenessCheckService();
const completenessCheckDetailsService = new CompletenessCheckDetailsService();

interface CompletenessState {
    filterOptions: CompletenessCheckFilterOptions;
    counts: {
        redCount: number;
        yellowCount: number;
        greenCount: number;
    };
    items: CompletenessCheckUnit[];
    detailItems: CompletenessCheckDetailItem[];
    filteredItems: CompletenessCheckDetailItem[];
    filteredItemsCount: number;
}

const state: CompletenessState = {
    filterOptions: [],
    counts: {
        redCount: 0,
        yellowCount: 0,
        greenCount: 0,
    },
    items: [],
    detailItems: [],
    filteredItems: [],
    filteredItemsCount: 0,
};

const SET_COMPLETENESS_DATA = 'setCompletenessData';
const RESET_COMPLETENESS_DATA = 'resetCompletenessData';
const SET_COMPLETENESS_DETAIL_DATA = 'setCompletenessDetailData';
const RESET_COMPLETENESS_DETAIL_DATA = 'resetCompletenessDetailData';
const SET_COMPLETENESS_DETAIL_FILTERED_DATA = 'setCompletenessDetailFilteredData';
const RESET_COMPLETENESS_DETAIL_FILTERED_DATA = 'resetCompletenessDetailFilteredData';
const SET_COMPLETENESS_DETAIL_FILTERED_COUNT = 'setCompletenessDetailFilteredCount';
const RESET_COMPLETENESS_DETAIL_FILTERED_COUNT = 'resetCompletenessDetailFilteredCount';
const SET_FILTER_OPTIONS = 'setFilterOptions';
const RESET_FILTER_OPTIONS = 'resetFilterOptions';

const CompletenessModule: Module<CompletenessState, any> = {
    state,

    mutations: {
        [SET_COMPLETENESS_DATA](state, data: CompletenessCheckResponse): void {
            state.items = data.units;
            state.counts.redCount = data.redCount;
            state.counts.yellowCount = data.yellowCount;
            state.counts.greenCount = data.greenCount;
        },
        [RESET_COMPLETENESS_DATA](state): void {
            state.items = [];
            state.counts.redCount = 0;
            state.counts.yellowCount = 0;
            state.counts.greenCount = 0;
        },
        [SET_COMPLETENESS_DETAIL_DATA](state, data: CompletenessCheckDetailItem[]): void {
            state.detailItems = data;
        },
        [RESET_COMPLETENESS_DETAIL_DATA](state): void {
            state.detailItems = [];
        },
        [SET_COMPLETENESS_DETAIL_FILTERED_DATA](state, data: CompletenessCheckDetailItem[]): void {
            state.filteredItems = data;
        },
        [RESET_COMPLETENESS_DETAIL_FILTERED_DATA](state): void {
            state.filteredItems = [];
        },
        [SET_COMPLETENESS_DETAIL_FILTERED_COUNT](state, data: number): void {
            state.filteredItemsCount = data;
        },
        [RESET_COMPLETENESS_DETAIL_FILTERED_COUNT](state): void {
            state.filteredItemsCount = 0;
        },
        [SET_FILTER_OPTIONS](state, data: CompletenessCheckFilterOptionsResponse): void {
            state.filterOptions = data.countriesWithUnits;
        },
        [RESET_FILTER_OPTIONS](state): void {
            state.filterOptions = [];
        }
    },

    actions: {
        async fetchItems({ commit }, { signal, ...params }: PayloadWithSignal<CompletenessCheckParams>): Promise<void> {
            try {
                const response = await completenessCheckService.fetchCompletenessCheckItems(params, signal);
                commit(SET_COMPLETENESS_DATA, response.result);
            } catch (e) {
                commit(RESET_COMPLETENESS_DATA);
                if (!signal.aborted) {
                    throw e;
                }
            }
        },
        async fetchFilterOptions({ commit }): Promise<void> {
            try {
                const response = await completenessCheckService.fetchCompletenessCheckFilterItems();
                commit(SET_FILTER_OPTIONS, response.result);
            } catch (e) {
                commit(RESET_FILTER_OPTIONS);
                if (!axios.isCancel(e)) {
                    throw e;
                }
            }
        },
        async fetchDetailItems({ commit }, { signal, ...params }: PayloadWithSignal<GetCompletenessCheckDetailItemPayload>): Promise<void> {
            try {
                const response = await completenessCheckDetailsService.fetchCompletenessCheckDetailsItems(params, signal);
                commit(SET_COMPLETENESS_DETAIL_DATA, response.result.completenessCheckDetailsDtos);
                commit(SET_COMPLETENESS_DETAIL_FILTERED_DATA, response.result.completenessCheckDetailsDtos);
                commit(SET_COMPLETENESS_DETAIL_FILTERED_COUNT, response.result.total);
            } catch (e) {
                commit(RESET_COMPLETENESS_DETAIL_DATA);
                commit(RESET_COMPLETENESS_DETAIL_FILTERED_COUNT);
                if (!signal.aborted) {
                    throw e;
                }
            }
        },
        async fetchFilteredDetailItems({ commit }, { signal, ...params }: PayloadWithSignal<GetCompletenessCheckDetailItemPayload>): Promise<void> {
            try {
                const response = await completenessCheckDetailsService.fetchCompletenessCheckDetailsItems(params, signal);
                commit(SET_COMPLETENESS_DETAIL_FILTERED_DATA, response.result.completenessCheckDetailsDtos);
                commit(SET_COMPLETENESS_DETAIL_FILTERED_COUNT, response.result.total);
            } catch (e) {
                commit(RESET_COMPLETENESS_DETAIL_DATA);
                commit(RESET_COMPLETENESS_DETAIL_FILTERED_COUNT);
                if (!signal.aborted) {
                    throw e;
                }
            }
        }
    },

    getters: {
        getCalendarItems(state): CompletenessCheckUnit[] {
            return state.items;
        },
        getItemCount(state): CompletenessCheckCountResponse {
            return {
                redCount: state.counts.redCount,
                yellowCount: state.counts.yellowCount,
                greenCount: state.counts.greenCount,
            };
        },
        getFilterOptions(state): CompletenessCheckFilterOptions {
            return state.filterOptions;
        },
        getDetailItems(state): CompletenessCheckDetailItem[] {
            return state.detailItems;
        },
        getFilteredItems(state): CompletenessCheckDetailItem[] {
            return state.filteredItems;
        },
        getFilteredItemsCount(state): number {
            return state.filteredItemsCount;
        },
    },

    namespaced: true
};

export default CompletenessModule;
