import { Module } from 'vuex';
import {
    MarkAsReadPayload,
    MonitorDataParams,
    MonitorDetailsDataParams,
    MonitorDetailsResponse,
    Process
} from '@/models/interfaces';
import axios, { CancelToken } from 'axios';
import { MonitorDetailsService } from '@/services/monitor-details-service';
import { MonitorOverviewService } from '@/services/monitor-overview-service';

const monitorDetailsService = new MonitorDetailsService();
const monitorOverviewService = new MonitorOverviewService();

const state: ProcessState = {
    processes: [] as Process[],
    subprocessesDetails: {} as MonitorDetailsResponse
};
interface ProcessState {
    processes: Process[];
    subprocessesDetails: MonitorDetailsResponse;
}
const SET_PROCESSES = 'setProcesses';
const RESET_PROCESSES = 'resetProcesses';
const SET_SUBPROCESSES_DETAILS = 'setSubprocessesDetails';
const RESET_SUBPROCESSES_DETAILS = 'resetSubprocessesDetails';

const ProcessModule: Module<ProcessState, any> = {
    state: state,

    mutations: {
        [SET_PROCESSES](state, processes: Process[]): void {
            state.processes = processes;
        },
        [RESET_PROCESSES](state): void {
            state.processes = [] as Process[];
        },
        [SET_SUBPROCESSES_DETAILS](state, subprocessDetails: MonitorDetailsResponse): void {
            state.subprocessesDetails = subprocessDetails;
        },
        [RESET_SUBPROCESSES_DETAILS](state): void {
            state.subprocessesDetails = {} as MonitorDetailsResponse;
        }
    },

    actions: {
        async fetchProcesses({ commit }, { fromDate, toDate, cancelToken }: MonitorDataParams): Promise<void> {
            try {
                const response = await monitorOverviewService.fetchProcesses(fromDate, toDate, cancelToken);
                commit(SET_PROCESSES, response.result.processes);
            } catch(e) {
                commit(RESET_PROCESSES);
                if (!axios.isCancel(e)) {
                    throw e;
                }
            }
        },

        async fetchSubprocessDetails({ commit }, { params, cancelToken }: { params: MonitorDetailsDataParams; cancelToken: CancelToken }): Promise<void> {
            try {
                const response = await monitorDetailsService.fetchSubprocessDetails(params, cancelToken);
                commit(SET_SUBPROCESSES_DETAILS, response.result);
            } catch(e) {
                commit(RESET_SUBPROCESSES_DETAILS);
                if (!axios.isCancel(e)) {
                    throw e;
                }
            }
        },

        async markSubprocessDetailsAsRead(context, payload: MarkAsReadPayload): Promise<void> {
            await monitorDetailsService.markSubprocessDetailsAsRead(payload);
        }
    },

    getters: {
        getProcesses(state): Process[] {
            return state.processes;
        },
        getSubprocesseDetails(state): MonitorDetailsResponse {
            return state.subprocessesDetails;
        }
    },
    namespaced: true
}

export default ProcessModule;
