






































import Component from 'vue-class-component';
import Vue from 'vue';
import { Emit, Prop } from 'vue-property-decorator';
import { SelectOption } from '@/models/interfaces';

export const TablePaginationDefaults = {
    INITIAL_PAGE_SIZE: 10,
    INITIAL_PAGE_NUMBER: 1,
    PAGE_SIZE_SELECTION_OPTIONS: [
        10, 25, 50, 100
    ]
};

@Component({})
export default class TablePaginationComponent extends Vue {
    @Prop({ required: true, default: 0 })
    totalCount!: number;

    selectedPageSize = TablePaginationDefaults.INITIAL_PAGE_SIZE;
    selectedPageNumber = TablePaginationDefaults.INITIAL_PAGE_NUMBER;

    private get paginationOptions(): SelectOption[] {
        const availablePageSizes = TablePaginationDefaults.PAGE_SIZE_SELECTION_OPTIONS
            .filter(option => option < this.totalCount);

        if (!availablePageSizes.length) {
            this.selectedPageSize = this.totalCount;

            return [{
                label: this.totalCount.toString(),
                value: this.totalCount.toString()
            }];
        }

        if (this.selectedPageSize < TablePaginationDefaults.INITIAL_PAGE_SIZE) {
            this.selectedPageSize = TablePaginationDefaults.INITIAL_PAGE_SIZE;
        }

        return availablePageSizes.map(option => ({
            label: option.toString(),
            value: option.toString()
        }));
    }

    private get totalPages(): number {
        return this.totalCount === 0 ? 0 : Math.ceil(this.totalCount / this.selectedPageSize);
    }

    created(): void {
        this.pageNumberChanged();
        this.pageSizeChanged();
    }

    @Emit('change:page-number')
    pageNumberChanged(): number {
        return this.selectedPageNumber;
    }

    @Emit('change:page-size')
    pageSizeChanged(): number {
        return this.selectedPageSize;
    }

    public goToFirstPage(): void {
        this.selectedPageNumber = 1;
        this.pageNumberChanged();
    }

    private goToLastPage(): void {
        this.selectedPageNumber = this.totalPages;
        this.pageNumberChanged();
    }

    private onPageSizeChange(newSize: string): void {
        this.selectedPageSize = parseInt(newSize);
        this.goToFirstPage();
        this.pageSizeChanged();
    }
}
