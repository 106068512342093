










































































import Component from 'vue-class-component';
import Vue from 'vue';
import {
    PuiAutoRefreshOptions,
    PuiDateBottomBarRange,
    PuiDateRangeOptions,
    PuiTableVendorOptions
} from '@/models/pebble-ui';
import { endOfDay, format, startOfDay, subDays, subMonths, subWeeks, addDays } from '@enerlytics/time-helper/dist/date-fns';
import { CANCEL_MESSAGE, QMDI_NAMESPACE } from '@/config/consts';
import { MonitorDataParams, OverviewValueType, Process, ProcessTableValue } from '@/models/interfaces'
import TableCell from './process-overview-values-table-cell/process-overview-values-table-cell.vue';
import LegendItem from '@/components/ui/legend-item/legend-item.vue';
import { CancelTokenSource } from 'axios';
import { PUICOLOR_DARK_GREY } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { AMBER_STATUS, GREEN_STATUS, GREY_STATUS, PROCESS_STATUS, RED_STATUS } from '@/config/process-statuses';
import { MONITOR__DETAILS_PATH } from '@/config/router-config';
import { PROCESSES, SUB_PROCESSES } from '@/config/processes';
import { ADD_REFRESH_ACTION, RefreshInterval, REMOVE_REFRESH_ACTION } from '@/store/modules/refresh.module';

@Component({
    components: {
        TableCell,
        LegendItem
    }
})
export default class MonitorComponent extends Vue {
    private readonly axios = require('axios');
    private readonly MONITOR_RANGE_KEY = `${QMDI_NAMESPACE}monitorRange`;
    private readonly END_OF_DAY = endOfDay(new Date());
    private readonly VALUE_CELLS = Object.values(SUB_PROCESSES).map((subProcess) => subProcess.label);
    private readonly COLUMNS = ['process', ...this.VALUE_CELLS];
    private readonly PUICOLOR_DARK_GREY = PUICOLOR_DARK_GREY;
    private readonly PROCESS_STATUS = PROCESS_STATUS;
    private readonly IMPORT_STATUSES = [RED_STATUS, AMBER_STATUS, GREEN_STATUS, GREY_STATUS];
    private getActiveDateTimeFrom: string | null = null;
    private getActiveDateTimeTo: string | null = null;
    private optionsSelectorModel = null;
    private processPromise: Promise<void> | null = null;
    private source = {} as CancelTokenSource;
    private headings = {} as Record<string, string>;

    private get optionsSelectorConfiguration(): PuiDateRangeOptions {
        return [
            {
                label: this.$t('report'),
                secondaryLabel: this.$t('report'),
                value: {
                    start: startOfDay(subDays(this.END_OF_DAY, 3)),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
            {
                label: this.$t('dayShort'),
                secondaryLabel: this.$t('day'),
                value: {
                    start: startOfDay(this.END_OF_DAY),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
            {
                label: this.$t('weekShort'),
                secondaryLabel: this.$t('week'),
                value: {
                    start: addDays(startOfDay(subWeeks(this.END_OF_DAY, 1)), 1),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
            {
                label: this.$t('monthShort'),
                secondaryLabel: this.$t('month'),
                value: {
                    start: addDays(startOfDay(subMonths(this.END_OF_DAY, 1)), 1),
                    end: this.END_OF_DAY
                },
                selected: false,
            },
        ];
    }

    private get optionsAutoRefresh(): PuiAutoRefreshOptions {
        const options = [
            {
                label: this.$t('refresh.refreshOnce'),
                value: RefreshInterval.REFRESH_ONCE,
            },
            {
                label: this.$t('refresh.noRefresh'),
                value: RefreshInterval.NO_REFRESH,
            },
            {
                label: this.$t('refresh.refreshEveryMinute'),
                value: RefreshInterval.REFRESH_EVERY_MINUTE,
            },
            {
                label: this.$t('refresh.refreshEveryFiveMinutes'),
                value: RefreshInterval.REFRESH_EVERY_FIVE_MINUTES,
            },
            {
                label: this.$t('refresh.refreshEveryTwentyMinutes'),
                value: RefreshInterval.REFRESH_EVERY_TWENTY_MINUTES,
            },
            {
                label: this.$t('refresh.refreshEveryHour'),
                value: RefreshInterval.REFRESH_EVERY_HOUR,
            },
        ];

        return options.map(e => ({
            ...e,
            selected: e.value === this.currentRefreshInterval,
            primary: false,
            disabled: false,
        }));
    }

    private get remainingRefreshTime(): string {
        const remainingTime = this.$store.getters['refresh/getRemainingTime'];
        return format(new Date(remainingTime), 'mm:ss');
    }

    private get currentRefreshInterval(): RefreshInterval {
        return this.$store.getters['refresh/getRefreshInterval'];
    }

    private get processes(): Process[] {
        return this.$store.getters['process/getProcesses'];
    }

    private get range(): PuiDateBottomBarRange {
        return {
            start: this.getActiveDateTimeFrom,
            end: this.getActiveDateTimeTo,
        };
    }

    private get vendorOptions(): PuiTableVendorOptions<ProcessTableValue[]> {
        const data = Object.keys(PROCESSES).map((process) => {
            const label = PROCESSES[Number(process)].label;
            return {
                process: label,
                ...this.getValuesColumns(Number(process))
            }
        });
        return {
            data: data,
            options: {
                sortable: [],
                headings: this.headings
            },
            columns: this.COLUMNS
        }
    }

    created(): void {
        if (sessionStorage.getItem(this.MONITOR_RANGE_KEY) !== null) {
            this.getActiveDateTimeFrom = JSON.parse(sessionStorage.getItem(this.MONITOR_RANGE_KEY) as string).start;
            this.getActiveDateTimeTo = JSON.parse(sessionStorage.getItem(this.MONITOR_RANGE_KEY) as string).end;
            let existingModel = null;
            for (const model of this.optionsSelectorConfiguration) {
                if (this.getActiveDateTimeFrom && this.getActiveDateTimeTo &&
                    model.value.start.toISOString() === new Date(this.getActiveDateTimeFrom).toISOString() &&
                    model.value.end.toISOString() === new Date(this.getActiveDateTimeTo).toISOString()) {
                    existingModel = model;
                    break;
                }
            }
            if (existingModel) {
                existingModel.selected = true;
            }
        } else {
            this.optionsSelectorConfiguration[0].selected = true;
        }
        this.COLUMNS.forEach((column) => {
            this.headings[column] = this.$t(column);
        })
    }

    private mounted(): void {
        this.$store.dispatch('refresh/initializeFromSessionStorageOrDefault');
        this.$store.commit(`refresh/${ADD_REFRESH_ACTION}`, this.fetchProcesses);
    }

    private beforeDestroy(): void {
        this.$store.commit(`refresh/${REMOVE_REFRESH_ACTION}`, this.fetchProcesses);
        this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
    }

    private rangeChanged({ start, end }: PuiDateBottomBarRange): void {
        this.getActiveDateTimeFrom = start;
        this.getActiveDateTimeTo = end;
        sessionStorage.setItem(this.MONITOR_RANGE_KEY, JSON.stringify(this.range));
        this.fetchProcesses();
    }

    private refreshIntervalChanged(refreshInterval: RefreshInterval): void {
        this.$store.dispatch('refresh/setInterval', refreshInterval);
    }

    private getValuesColumns(process: number): Omit<ProcessTableValue, 'process'> {
        const valuesColumns = {} as Omit<ProcessTableValue, 'process'>;
        const processValue = this.processes.find((p) => p.processType === process);
        Object.keys(SUB_PROCESSES).forEach((subProcess) => {
            const subProcessValue = processValue?.processValue?.subProcesses ? processValue.processValue.subProcesses.find((s) => s.subProcessType === Number(subProcess)) : null;
            const label = SUB_PROCESSES[Number(subProcess)].label as keyof Omit<ProcessTableValue, 'process'>;
            valuesColumns[label] = {
                history: subProcessValue?.subProcessValue.history ?? 0,
                current: subProcessValue?.subProcessValue.current ?? 0,
                date: subProcessValue?.subProcessValue.date ?? '',
            }
        });
        return valuesColumns;
    }

    private fetchProcesses(): void {
        if (this.range.start && this.range.end) {
            this.source.cancel && this.source.cancel(CANCEL_MESSAGE);
            this.source = this.axios.CancelToken.source();
            this.processPromise = this.$store.dispatch('process/fetchProcesses', { fromDate: this.range.start, toDate: this.range.end, cancelToken: this.source.token } as MonitorDataParams);
        }
    }

    private async goToDetails(process: string, subProcess: string, overviewValueType: OverviewValueType): Promise<void> {
        const processValue = Object.keys(PROCESSES).find((p) => PROCESSES[Number(p)].label === process);
        const subProcessValue = Object.keys(SUB_PROCESSES).find((p) => SUB_PROCESSES[Number(p)].label === subProcess);
        await this.$router.push(`${MONITOR__DETAILS_PATH}/${processValue}/${subProcessValue}/${overviewValueType}/${this.range.start}/${this.range.end}`);
    }
}
