







import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class Tooltip extends Vue {
    /**
     * Message to be shown in tooltip.
     */
    @Prop()
    private message!: string;
}
