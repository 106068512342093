import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'
import PebbleUI from '@enerlytics/pebble-ui/dist/pebble-ui';
import { PuiSelectOptions } from '@/models/pebble-ui';
import { QMDI_NAMESPACE, DEFAULT_LOCALE } from '@/config/consts'

Vue.use(VueI18n)

export const i18n = new VueI18n({
    locale: DEFAULT_LOCALE,
    fallbackLocale: DEFAULT_LOCALE,
    messages: require(`@/locales/${DEFAULT_LOCALE}.json`),
})

const loadedLanguages = [DEFAULT_LOCALE];

function setI18nLanguage(lang: string): string {
    i18n.locale = lang
    PebbleUI.lang.setLanguage(lang)
    localStorage.setItem(`${QMDI_NAMESPACE}locale`, lang)

    if (axios.defaults.headers) {
        axios.defaults.headers.common['Accept-Language'] = lang
    }

    document.querySelector('html')?.setAttribute('lang', lang)

    return lang
}

export function loadLanguageAsync(lang: string): Promise<string | void> {
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang))
    }

    return import(`@/locales/${lang}.json`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages[lang])
            loadedLanguages.push(lang)
            return setI18nLanguage(lang)
        }
    )
}

export function getLanguageFlag(locale: string): string {
    return require(`@/assets/img/${locale.substr(0, 2)}-icon.png`);
}

export function languageOptions(): PuiSelectOptions {
    return [
        { label: 'English (UK)', value: 'en-GB' },
        { label: 'Deutsch (DE)', value: 'de-DE' },
    ]
}
