








































































import {Component, Vue} from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import clickOutside from '@/directives/click-outside';
import { APP_SCOPE } from '@/utils/coode-sdk-config';

@Component({
    directives: {
        clickOutside,
    },
})
export default class UserProfile extends Vue {
    private version: string | undefined = process.env.VUE_APP_VERSION;
    private apiUrl: string | undefined = process.env.VUE_APP_API_BASE_URL;
    private swaggerURL: string | undefined = process.env.VUE_APP_API_SWAGGER_URL;
    private detailsToggled = false;

    private logOut(): void {
        this.$sdk.$authenticator?.logout();
    }

    private async copyToken(): Promise<void> {
        const textElement: any = document.getElementById('copyText');
        if (textElement) {
            const token = await this.$sdk.$authenticator?.getToken(APP_SCOPE);
            textElement.value = '';
            textElement.value = `Bearer ${token}`;
            textElement.select();
            document.execCommand('copy');
            EventBus.$emit(EventBus.GLOBAL.SHOW_SNACKBAR, 'copiedToClipboard');
        } else {
            throw new Error('Copy text element is undefined');
        }
    }

    private offFocus(): void {
        if (this.detailsToggled) {
            this.detailsToggled = false;
        }
    }

    private sendToFeedbackForm(): void {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID ?? 0);
        const appStoreUrl = `${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/contact-page/${useCaseId}?isFeedback=true`;

        window.open(appStoreUrl, '_blank');
    }

    private sendToIncidentReporting(): void {
        const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID ?? 0);
        const appStoreUrl = `${process.env.VUE_APP_EXTERNAL_APP_APPSTORE_URL}/incident-page/${useCaseId}`;

        window.open(appStoreUrl, '_blank');
    }
}
