import BaseService from '@/utils/base-service';
import { ApiResponse } from '@/models';
import {
    AddOrEditThresholdResponse,
    AddThresholdPayload, EditThresholdPayload,
    GetThresholdsResponseData
} from '@/models/services/thresholds';

/**
 * @name ThresholdsService
 * @description This class is used to communicate with the GDC server for
 * information related to thresholds.
 */
export class ThresholdsService extends BaseService {
    constructor() {
        super('threshold');
    }

    public async getThresholds(signal?: AbortSignal): Promise<ApiResponse<GetThresholdsResponseData>> {
        return (await this.api.get<ApiResponse<GetThresholdsResponseData>>(this.resourcePath, { signal })).data;
    }

    public async addThreshold(payload: AddThresholdPayload): Promise<ApiResponse<AddOrEditThresholdResponse>> {
        return (await this.api.post<ApiResponse<AddOrEditThresholdResponse>>(this.resourcePath, payload)).data;
    }

    public async editThreshold(payload: EditThresholdPayload): Promise<ApiResponse<AddOrEditThresholdResponse>> {
        return (await this.api.put<ApiResponse<AddOrEditThresholdResponse>>(this.resourcePath, payload)).data;
    }
}
