import { Module } from 'vuex';
import { QMDI_NAMESPACE } from '@/config/consts';
import { nullIfNaN } from '@/utils/number-utils';

export enum RefreshInterval {
    NO_REFRESH = 1,
    REFRESH_ONCE = 2,
    REFRESH_EVERY_MINUTE = 60000,
    REFRESH_EVERY_FIVE_MINUTES = 300000,
    REFRESH_EVERY_TWENTY_MINUTES = 1200000,
    REFRESH_EVERY_HOUR = 3600000,
}

export type RefreshAction = () => void;

interface RefreshState {
    initializedFromSessionStorage: boolean;
    refreshInterval: RefreshInterval;
    currentRefreshCounter: number;
    intervalId?: number;
    refreshActions: RefreshAction[];
}

const state: RefreshState = {
    initializedFromSessionStorage: false,
    refreshInterval: RefreshInterval.NO_REFRESH,
    currentRefreshCounter: 0,
    intervalId: undefined,
    refreshActions: [],
};

const TICK_DELAY = 1000 as const;

const SS_REFRESH_INTERVAL_KEY = `${QMDI_NAMESPACE}refresh.interval`;
const SS_REFRESH_COUNTER_KEY = `${QMDI_NAMESPACE}refresh.counter`;

const SET_AS_INITIALIZED_FROM_SESSION_STORAGE = 'setAsInitializedFromSessionStorage' as const;
const SET_REFRESH_INTERVAL = 'setRefreshInterval' as const;
const SET_REFRESH_COUNTER = 'setRefreshCounter' as const;
const RESET_REFRESH_COUNTER = 'resetRefreshCounter' as const;
const DECREMENT_REFRESH_COUNTER = 'decrementRefreshCounter' as const;
const SET_INTERVAL_ID = 'setIntervalId' as const;
export const ADD_REFRESH_ACTION = 'addRefreshAction' as const;
export const REMOVE_REFRESH_ACTION = 'removeRefreshAction' as const;

const RefreshModule: Module<RefreshState, any> = {
    state,

    mutations: {
        [SET_AS_INITIALIZED_FROM_SESSION_STORAGE](state): void {
            state.initializedFromSessionStorage = true;
        },
        [SET_REFRESH_INTERVAL](state, interval: RefreshInterval): void {
            state.refreshInterval = interval;
            state.currentRefreshCounter = interval;

            sessionStorage.setItem(SS_REFRESH_INTERVAL_KEY, state.refreshInterval.toString());
            sessionStorage.setItem(SS_REFRESH_COUNTER_KEY, state.currentRefreshCounter.toString());
        },
        [SET_REFRESH_COUNTER](state, value: number): void {
            state.currentRefreshCounter = value;

            sessionStorage.setItem(SS_REFRESH_COUNTER_KEY, state.currentRefreshCounter.toString());
        },
        [RESET_REFRESH_COUNTER](state): void {
            state.currentRefreshCounter = state.refreshInterval;

            sessionStorage.setItem(SS_REFRESH_COUNTER_KEY, state.currentRefreshCounter.toString());
        },
        [DECREMENT_REFRESH_COUNTER](state, value: number): void {
            state.currentRefreshCounter -= value;

            sessionStorage.setItem(SS_REFRESH_COUNTER_KEY, state.currentRefreshCounter.toString());
        },
        [SET_INTERVAL_ID](state, intervalId?: number): void {
            state.intervalId = intervalId;
        },
        [ADD_REFRESH_ACTION](state, refreshAction: RefreshAction): void {
            state.refreshActions = [...state.refreshActions, refreshAction];
        },
        [REMOVE_REFRESH_ACTION](state, refreshAction: RefreshAction): void {
            state.refreshActions = state.refreshActions.filter(e => e !== refreshAction);
        }
    },

    actions: {
        initializeFromSessionStorageOrDefault({ state, dispatch, commit }): void {
            if (state.initializedFromSessionStorage) {
                return;
            }

            const refreshInterval = nullIfNaN(parseInt(sessionStorage.getItem(SS_REFRESH_INTERVAL_KEY) ?? '', 10)) ?? RefreshInterval.REFRESH_EVERY_FIVE_MINUTES;
            const refreshCounter = nullIfNaN(parseInt(sessionStorage.getItem(SS_REFRESH_COUNTER_KEY) ?? '', 10)) ?? RefreshInterval.REFRESH_EVERY_FIVE_MINUTES;

            dispatch('setInterval', refreshInterval);

            commit(SET_REFRESH_COUNTER, refreshCounter);
            commit(SET_AS_INITIALIZED_FROM_SESSION_STORAGE);
        },
        setInterval({ dispatch, commit }, interval): void {
            commit(SET_REFRESH_INTERVAL, interval);

            dispatch('clearInterval');

            if (interval === RefreshInterval.REFRESH_ONCE) {
                dispatch('intervalTickElapsed', true);
            } else if (interval !== RefreshInterval.NO_REFRESH) {
                commit(SET_INTERVAL_ID, setInterval(() => dispatch('intervalTickElapsed'), TICK_DELAY));
            }
        },
        intervalTickElapsed({ state, dispatch, commit }, runOnce = false): void {
            if (runOnce) {
                dispatch('executeRefreshActions');
            } else {
                commit(DECREMENT_REFRESH_COUNTER, TICK_DELAY);

                if (state.currentRefreshCounter < 0) {
                    dispatch('executeRefreshActions');
                    commit(RESET_REFRESH_COUNTER);
                }
            }
        },
        executeRefreshActions({ state }): void {
            state.refreshActions.forEach(action => action());
        },
        clearInterval({ state, commit }): void {
            if (state.intervalId) {
                clearInterval(state.intervalId);
            }

            commit(SET_INTERVAL_ID);
        }
    },

    getters: {
        getRemainingTime(state): number {
            return state.currentRefreshCounter;
        },
        getRefreshInterval(state): RefreshInterval {
            return state.refreshInterval;
        }
    },

    namespaced: true
};

export default RefreshModule;
