































import Component from 'vue-class-component';
import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import LegendItem from '@/components/ui/legend-item/legend-item.vue';
import { AMBER_STATUS, PROCESS_STATUS, RED_STATUS } from '@/config/process-statuses';
import { PUICOLOR_WARM_GREY } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { OverviewValueType } from '@/models/interfaces'

@Component({
    components: {
        LegendItem
    }
})
export default class ProcessOverviewValuesTableCellComponent extends Vue {
    private readonly OVERVIEW_VALUE_TYPE_HISTORIC = OverviewValueType.HISTORIC;
    private readonly OVERVIEW_VALUE_TYPE_CURRENT = OverviewValueType.CURRENT;
    private readonly DEFAULT_STATUS_COLOR = PUICOLOR_WARM_GREY;

    /**
     * This prop is used to know the flag for history status.
     */
    @Prop({ default: '' })
    private historyFlag?: number;

    /**
     * This prop is used to know the flag for the current status.
     */
    @Prop({ default: '' })
    private currentFlag?: number;

    /**
     * This prop is used to know the process date.
     */
    @Prop({ default: null })
    private date?: string;

    /**
     * This prop is used to know if legend is clickable.
     */
    @Prop({ default: false })
    private isLegendClickable?: boolean;

    private get historyStatusColor(): string {
        return this.historyFlag ? PROCESS_STATUS[this.historyFlag]?.color : this.DEFAULT_STATUS_COLOR ;
    }

    private get currentStatusColor(): string {
        return this.currentFlag ? PROCESS_STATUS[this.currentFlag]?.color : this.DEFAULT_STATUS_COLOR ;
    }

    private get isHistoryClickable(): boolean | undefined {
        return this.isLegendClickable && (this.historyFlag === AMBER_STATUS || this.historyFlag === RED_STATUS);
    }

    private get isCurrentClickable(): boolean | undefined {
        return this.isLegendClickable && (this.currentFlag === AMBER_STATUS || this.currentFlag === RED_STATUS);
    }

    private handleLegendClicked(overviewValueType: OverviewValueType): void {
        this.$emit('legendClicked', { overviewValueType })
    }
}
