

































import Vue from 'vue';
import Component from 'vue-class-component';
import { AUXILIARY, COUNTED, DAY_AHEAD, MEASURED, SUB_PROCESSES } from '@/config/processes';
import { ThresholdItem, GetThresholdsSubprocessItem } from '@/models/services/thresholds';
import ThresholdsTable from '@/components/settings/thresholds-tab/thresholds-table/thresholds-table.vue';
import EditThresholdModalComponent
    from '@/components/settings/thresholds-tab/edit-threshold-modal/edit-threshold-modal.vue';

const REF_CONSTANTS = {
    EDIT_THRESHOLD_MODAL: 'editThresholdModal',
} as const;

@Component({
    components: {
        ThresholdsTable,
        EditThresholdModalComponent,
    }
})
export default class ThresholdsTabComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly SUB_PROCESSES = SUB_PROCESSES;
    private readonly VALID_SUBPROCESSES = [DAY_AHEAD, COUNTED, MEASURED, AUXILIARY];

    private abortController = new AbortController();
    private thresholdsPromise: Promise<void> | null = Promise.resolve();

    $refs!: {
        [REF_CONSTANTS.EDIT_THRESHOLD_MODAL]: EditThresholdModalComponent;
    };

    private get thresholdData(): GetThresholdsSubprocessItem[] {
        return this.$store.getters['thresholds/getThresholds'];
    }

    created(): void {
        this.loadThresholds();
    }

    beforeDestroy(): void {
        this.abortController.abort();
    }

    private loadThresholds(): void {
        this.abortController.abort();
        this.abortController = new AbortController();

        this.thresholdsPromise = this.$store.dispatch('thresholds/fetchThresholds', { signal: this.abortController.signal });
    }

    private getThresholdItemsForSubProcess(subProcess: number): ThresholdItem[] {
        const thresholdDataItem = this.thresholdData.find(e => e.subProcess === subProcess);
        return thresholdDataItem?.thresholds ?? [];
    }

    private addThresholdClicked(subProcess: number): void {
        this.$refs[REF_CONSTANTS.EDIT_THRESHOLD_MODAL].openModalForAdd(subProcess);
    }

    private editThresholdClicked(subProcess: number, thresholdItem: ThresholdItem): void {
        this.$refs[REF_CONSTANTS.EDIT_THRESHOLD_MODAL].openModalForEdit(subProcess, thresholdItem);
    }
}
