


















































































import Component from 'vue-class-component';
import Vue from 'vue';
import { PuiLightbox } from '@/models/pebble-ui';
import { EmailSettingsItem, EmailTemplateItem, EmailTemplateTagItem } from '@/models/interfaces';
import { PUICOLOR_UNIPER_BLUE } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { isLastCharacterWhitespaceOrEmpty } from '@/utils/string-utils';
import { PROCESS_STATUS } from '@/config/process-statuses';
import { PROCESSES, SUB_PROCESSES } from '@/config/processes';
import { EmailTemplateSectionIdentifier } from '@/store/modules/settings.module';
import { emailValidationFn, notNullOrEmptyValidationFn } from '@/utils/validation-utils';

enum EMAIL_TEMPLATE_TYPE {
    STANDARD = 1,
    COMMENTS = 2
}

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({})
export default class EditEmailTemplateModalComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly CHIP_BORDER_COLOR = PUICOLOR_UNIPER_BLUE;
    private readonly TAG_DATA_MIME_TYPE = 'application/qmdi-tag';

    private abortController = new AbortController();
    private emailTemplatePromise: Promise<void> | null = null;
    private emailTemplateType = EMAIL_TEMPLATE_TYPE.STANDARD;
    private isFormLoading = false;

    private formData = {
        email: {
            value: '',
            isValid: true,
            validationFn: emailValidationFn,
        },
        subject: {
            value: '',
            isValid: true,
            validationFn: notNullOrEmptyValidationFn,
        },
        body: {
            value: '',
            isValid: true,
            validationFn: notNullOrEmptyValidationFn,
        },
    };

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    private get showEmailField(): boolean {
        return this.emailTemplateType === EMAIL_TEMPLATE_TYPE.STANDARD;
    }

    private get isFormDirty(): boolean {
        if (!this.emailTemplate) {
            return false;
        }

        return (this.showEmailField && this.formData.email.value !== this.emailTemplate.email)
            || this.formData.subject.value !== this.emailTemplate.subject
            || this.formData.body.value !== this.emailTemplate.template;
    }

    private get isFormValid(): boolean {
        return Object.keys(this.formData)
            .map(key => this.formData[key].isValid)
            .reduce((previousValue, currentValue) => previousValue && currentValue, true);
    }

    private get isFormSubmittable(): boolean {
        return !this.isFormLoading
            && this.isFormDirty
            && this.isFormValid;
    }

    private get lightboxTitle(): string {
        if (!this.emailTemplate) {
            return this.$t('loading');
        }

        if (this.emailTemplateType === EMAIL_TEMPLATE_TYPE.STANDARD) {
            return this.$t('emailTemplateModal.title', {
                color: this.$t(PROCESS_STATUS[this.emailTemplate.emailSettingsId.color].statusColorName),
                process: this.$t(PROCESSES[this.emailTemplate.emailSettingsId.process].label),
                subProcess: this.$t(SUB_PROCESSES[this.emailTemplate.emailSettingsId.subProcess].label),
            });
        } else {
            return this.$t('emailTemplateModal.titleComments');
        }
    }

    private get emailTemplateTags(): EmailTemplateTagItem[] {
        return this.emailTemplateType === EMAIL_TEMPLATE_TYPE.STANDARD
            ? this.$store.getters['settings/getEmailTemplateTags']
            : this.$store.getters['settings/getEmailCommentsTags'];
    }

    private get emailTemplate(): EmailTemplateItem | null {
        return this.emailTemplateType === EMAIL_TEMPLATE_TYPE.STANDARD
            ? this.$store.getters['settings/getEmailTemplate']
            : this.$store.getters['settings/getEmailCommentsTemplate'];
    }

    public openEmailTemplate(emailSettingsItem?: EmailSettingsItem): void {
        let fetchPromise: Promise<void>;
        this.isFormLoading = true;

        if (emailSettingsItem) {
            this.emailTemplateType = EMAIL_TEMPLATE_TYPE.STANDARD;
            fetchPromise = this.$store.dispatch('settings/fetchEmailTemplate', {
                signal: this.abortController.signal,
                emailSettingsId: emailSettingsItem.emailSettingsId,
            });
        } else {
            this.emailTemplateType = EMAIL_TEMPLATE_TYPE.COMMENTS;
            fetchPromise = this.$store.dispatch('settings/fetchEmailCommentsTemplate', {
                signal: this.abortController.signal,
            });
        }

        this.emailTemplatePromise = fetchPromise.catch(() => {
            this.$refs[REF_CONSTANTS.LIGHTBOX].close();
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('apiError.errorGettingData'),
                copy: this.$t('apiError.couldNotGetEmailSettings')
            });
        }).then(() => {
            this.loadEmailTemplateIntoFormData();
            this.isFormLoading = false;
        });

        this.$refs[REF_CONSTANTS.LIGHTBOX].open();
    }

    private onLightboxConfirm(): void {
        this.validateForm();

        if (!this.emailTemplate || !this.isFormValid) {
            return;
        }

        this.isFormLoading = true;

        const updatePromise = this.emailTemplateType === EMAIL_TEMPLATE_TYPE.STANDARD
            ? this.getUpdateEmailTemplatePromise()
            : this.getUpdateEmailCommentsTemplatePromise();

        this.emailTemplatePromise = updatePromise.then(() => {
            this.$emit('update:email-template');

            (this as any).$pui.toast({
                type: 'success',
                title: this.$t('apiSuccess.savedSuccessfully'),
                copy: this.$t('apiSuccess.emailTemplateSaved'),
            });
        }).catch(() => {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('apiError.saveFailed'),
                copy: this.$t('apiError.emailTemplateSaved'),
            });
        }).finally(() => {
            this.$refs[REF_CONSTANTS.LIGHTBOX].close()
            this.isFormLoading = false;
        });
    }

    private onLightboxClose(): void {
        this.abortController.abort();
        this.abortController = new AbortController();
    }

    private getUpdateEmailTemplatePromise(): Promise<unknown> {
        if (!this.emailTemplate) {
            return Promise.reject();
        }

        const emailTemplateSectionIdentifier: EmailTemplateSectionIdentifier = {
            color: this.emailTemplate.emailSettingsId.color,
            process: this.emailTemplate.emailSettingsId.process
        };

        return this.$store.dispatch('settings/updateEmailTemplate', {
            emailSettingsId: this.emailTemplate.emailSettingsId,
            email: this.formData.email.value.trim(),
            subject: this.formData.subject.value.trim(),
            template: this.formData.body.value.trim(),
        }).then(() => {
            this.$store.dispatch('settings/setOpenedEmailTemplateSection', emailTemplateSectionIdentifier);
        });
    }

    private getUpdateEmailCommentsTemplatePromise(): Promise<unknown> {
        if (!this.emailTemplate) {
            return Promise.reject();
        }

        return this.$store.dispatch('settings/updateEmailCommentsTemplate', {
            subject: this.formData.subject.value.trim(),
            template: this.formData.body.value.trim(),
        });
    }

    private loadEmailTemplateIntoFormData(): void {
        if (!this.emailTemplate) {
            return;
        }

        this.clearValidationState();

        this.formData.email.value = this.emailTemplate.email ?? '';
        this.formData.subject.value = this.emailTemplate.subject ?? '';
        this.formData.body.value = this.emailTemplate.template ?? '';

        this.isFormLoading = false;
    }

    private validateForm(): void {
        Object.keys(this.formData).forEach(key => this.validateField(key, this.formData[key].value));

        if (!this.showEmailField) {
            this.formData.email.isValid = true;
        }
    }

    private validateField(key: string, input: string): void {
        this.formData[key].isValid = this.formData[key].validationFn(input);
    }

    private clearValidationState(): void {
        Object.keys(this.formData).forEach(key => this.formData[key].isValid = true);
    }

    private tagDragStart(event: DragEvent, tagContent: string): void {
        if (!event.dataTransfer) {
            return;
        }

        event.dataTransfer.setData(this.TAG_DATA_MIME_TYPE, tagContent);
        event.dataTransfer.effectAllowed = 'copy';
    }

    private textFieldDragOver(event: DragEvent): void {
        if (event.dataTransfer?.types.includes(this.TAG_DATA_MIME_TYPE)) {
            event.preventDefault();
            event.dataTransfer.effectAllowed = 'copy';
        }
    }

    private textFieldDrop(event: DragEvent, callback: (arg0: string) => void): void {
        if (!event.dataTransfer || !event.dataTransfer.types.includes(this.TAG_DATA_MIME_TYPE)) {
            return;
        }

        event.preventDefault();
        const tagData = event.dataTransfer.getData(this.TAG_DATA_MIME_TYPE);
        callback(tagData);
    }

    private handleDropForSubject(tagData: string): void {
        if (!isLastCharacterWhitespaceOrEmpty(this.formData.subject.value)) {
            tagData = ` ${tagData} `;
        } else {
            tagData = `${tagData} `;
        }

        this.formData.subject.value += tagData;
        this.validateForm();
    }

    private handleDropForBody(tagData: string): void {
        if (!isLastCharacterWhitespaceOrEmpty(this.formData.body.value)) {
            tagData = ` ${tagData} `;
        } else {
            tagData = `${tagData} `;
        }

        this.formData.body.value += tagData;
        this.validateForm();
    }
}
