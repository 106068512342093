import { Module } from 'vuex';
import { FileResponse, ReportsExcelParams } from '@/models/interfaces';
import { ReportsService } from '@/services/reports-service';

const reportsService = new ReportsService();

const state: ProcessState = {
    excel: {} as FileResponse,
};
interface ProcessState {
    excel: FileResponse;
}

const SET_EXCEL = 'setReportsExcel';
const RESET_EXCEL = 'resetReportsExcel';

const ReportsModule: Module<ProcessState, any> = {
    state: state,

    mutations: {
        [SET_EXCEL](state, excel: FileResponse): void {
            state.excel = excel;
        },
        [RESET_EXCEL](state): void {
            state.excel = {} as FileResponse;
        },
    },

    actions: {
        async fetchReportsExcel({commit}, { params, signal }: { params: ReportsExcelParams; signal?: AbortSignal }): Promise<void> {
            try{
                const fileResponse = await reportsService.exportReport(params, signal);
                commit(SET_EXCEL, fileResponse);
            } catch(e) {
                commit(RESET_EXCEL);
                if (!signal?.aborted) {
                    throw e;
                }
            }
        },
    },

    getters: {
        getReportsExcel(state): FileResponse {
            return state.excel;
        },
    },
    namespaced: true
}

export default ReportsModule;
