












import { Component, Vue } from 'vue-property-decorator';
import HeaderWrapper from '@/components/header/header.vue';
import Snackbar from '@/components/snackbar/snackbar.vue';
import Loader from '@/components/loader/loader.vue';
import NavbarComponent from '@/components/navbar/navbar.vue';
import './main.less';
import { LocalStorageHelper } from '@coode/fe-sdk';
import { ExtendedUserPermissions } from '@/models/uam';
import { APP_SCOPE } from '@/utils/coode-sdk-config';

@Component({
    name: 'app',
    components: {
        HeaderWrapper,
        Snackbar,
        Loader,
        NavbarComponent
    },
})
export default class App extends Vue {
    private hasSecurityLoaded = false;

    private created(): void {
        this.setupCoodeSdk();
    }

    private async setupCoodeSdk(): Promise<void> {
        await this.$sdk.$authenticator?.loadAuthModule();
        await this.$sdk.$authenticator?.attemptSsoSilent('loginRedirect');

        await this.$sdk.$http?.$generic.request.api.setBaseUrl(process.env.VUE_APP_API_BASE_URL);

        await this.$sdk.$http?.$generic.request.api.setTokenCallback(
            async () => this.$sdk.$authenticator?.getToken(APP_SCOPE)
        );

        const isSignedIn = await this.$sdk.$authenticator?.isAuthenticated() as boolean;

        if (isSignedIn) {
            const useCaseId = Number(process.env.VUE_APP_USE_CASE_ID);
            const hasPermissionsSet = await this.$sdk.$permissions?.setPermissions(useCaseId);

            if (hasPermissionsSet) {
                const permissions = LocalStorageHelper.getPermissionsLocalStorage() as ExtendedUserPermissions;
                await this.$store.dispatch('user/setProfile', permissions);
            }

            this.hasSecurityLoaded = true;
        }
    }
}
