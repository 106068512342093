var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"calendar-list-view"},[_c('table',{staticClass:"calendar-list-view__table"},[_c('thead',[_c('tr',[_c('th',{staticClass:"calendar-list-view__table__unit-heading-cell"}),_vm._l((_vm.daysInDateRange),function(day,index){return _c('th',{key:index,staticClass:"calendar-list-view__table__status-heading-cell"},[_vm._v(" "+_vm._s(day)+" ")])})],2)]),_c('tbody',_vm._l((_vm.config.items),function(unit){return _c('tr',{key:unit.unitSid},[_c('td',{staticClass:"calendar-list-view__table__unit-data-cell"},[_vm._v(" "+_vm._s(((unit.unitName) + " (" + (unit.unitSid) + ")"))+" ")]),_vm._l((unit.days),function(day,index){return _c('td',{key:index,class:[{
                        'calendar-list-view__table__status-data-cell--first': index === 0,
                        'calendar-list-view__table__status-data-cell--last': index === _vm.days.length - 1,
                    }, 'calendar-list-view__table__status-data-cell']},[_c('div',{class:[{
                            'calendar-list-view__table__status-data-cell__item--first': index === 0,
                            'calendar-list-view__table__status-data-cell__item--last': index === _vm.days.length - 1,
                        }, 'calendar-list-view__table__status-data-cell__item'],style:(_vm.getStyleForContentCell(day.statusColor)),on:{"click":function($event){return _vm.unitClicked(day.statusColor, {
                            day: day.day,
                            unitName: unit.unitName,
                            unitSid: unit.unitSid,
                        })}}})])})],2)}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }