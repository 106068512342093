import BaseService from '@/utils/base-service';
import { MarkAsReadPayload, MonitorDetailsDataParams, MonitorDetailsResponse } from '@/models/interfaces';
import { ApiResponse } from '@/models';
import { CancelToken } from 'axios';

/**
 * @name MonitorDetailsService
 * @description This class is used to communicate with the GDC service for
 * information related to Monitor Details.
 */
export class MonitorDetailsService extends BaseService {
    constructor() {
        super('monitor-details');
    }

    public async fetchSubprocessDetails(params: MonitorDetailsDataParams, cancelToken?: CancelToken): Promise<ApiResponse<MonitorDetailsResponse>> {
        return (await this.api.post<ApiResponse<MonitorDetailsResponse>>(this.resourcePath, params, { cancelToken })).data;
    }

    public async markSubprocessDetailsAsRead(payload: MarkAsReadPayload): Promise<void> {
        await this.api.post(`${this.resourcePath}/mark-as-read`, payload);
    }
}
