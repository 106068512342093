





































































import Component from 'vue-class-component'
import Vue from 'vue'
import {
    CalendarViewConfiguration,
    CompletenessCheckCountResponse,
    CompletenessCheckUnit,
    CompletenessCheckFilterOptions,
    CompletenessCheckParams,
    UnitFilterOption, PayloadWithSignal
} from '@/models/interfaces'
import {
    addMonths,
    differenceInDays,
    endOfDay,
    startOfMonth,
    endOfMonth,
    format,
    startOfDay,
    subMonths,
} from '@enerlytics/time-helper/dist/date-fns';
import {
    PuiDateBottomBarRange,
    PuiFilterAppliedValues,
    PuiFilterConfig,
} from '@/models/pebble-ui'
import LegendItem from '@/components/ui/legend-item/legend-item.vue'
import CalendarListView, { CalendarUnitClickPayload } from '@/components/calendar-list-view/calendar-list-view.vue';
import CompletenessCheckDetailsModal from '@/components/completeness-check/completeness-check-details-modal/completeness-check-details-modal.vue';
import { PUICOLOR_DARK_GREY } from '@enerlytics/pebble-ui/dist/constants/colors.js';
import { AMBER_STATUS, GREEN_STATUS, PROCESS_STATUS, RED_STATUS } from '@/config/process-statuses';
import { Watch } from 'vue-property-decorator';
import { formatISO } from 'date-fns';

const REF_CONSTANTS = {
    COMPLETENESS_CHECK_DETAILS_MODAL: 'completenessCheckDetailsModal',
} as const;

enum CompletenessCheckType {
    DAY_AHEAD = 1,
    COUNTED = 2,
    MEASURED = 3,
    AUXILIARY = 4,
}

@Component({
    components: {
        LegendItem,
        CalendarListView,
        CompletenessCheckDetailsModal,
    }
})
export default class CompletenessCheckComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly PROCESS_STATUS = PROCESS_STATUS;
    private readonly COMPLETENESS_STATUSES = [GREEN_STATUS, AMBER_STATUS, RED_STATUS];
    private readonly PUICOLOR_DARK_GREY = PUICOLOR_DARK_GREY;
    private readonly LEGEND_LABEL_FONT_SIZE = '1.2rem';
    private readonly MAX_ONE_MONTH_IN_DAYS = 31;
    private readonly END_OF_DAY = endOfDay(new Date());
    private readonly CHECK_ICON_COLOR = PROCESS_STATUS[GREEN_STATUS].color;
    private readonly CHECK_ICON_SIZE = '12rem';

    private readonly TAB_INDEX_COMPLETENESS_CHECK_MAP: Record<number, CompletenessCheckType> = {
        0: CompletenessCheckType.COUNTED,
        1: CompletenessCheckType.MEASURED,
        2: CompletenessCheckType.DAY_AHEAD,
        3: CompletenessCheckType.AUXILIARY
    };

    private readonly SELECTABLE_RANGE: PuiDateBottomBarRange = {
        start: startOfDay(subMonths(new Date(), 18)).toISOString(),
        end: startOfDay(addMonths(new Date(), 18)).toISOString(),
    };

    $refs!: {
        [REF_CONSTANTS.COMPLETENESS_CHECK_DETAILS_MODAL]: CompletenessCheckDetailsModal;
    };

    private abortController = new AbortController();

    private currentTabIndex = 0;
    private completenessPromise: Promise<void> | null = null;
    private filterConfiguration: PuiFilterConfig = { filters: [] };
    private selectedCountries: number[] = [];
    private selectedUnits: number[] = [];

    private range: PuiDateBottomBarRange = {
        start: new Date().toISOString(),
        end: new Date().toISOString(),
    };

    private get calendarItems(): CompletenessCheckUnit[] {
        return this.$store.getters['completeness/getCalendarItems'];
    }

    private get completenessCheckCount(): CompletenessCheckCountResponse {
        return this.$store.getters['completeness/getItemCount'];
    }

    private get filterOptions(): CompletenessCheckFilterOptions {
        return this.$store.getters['completeness/getFilterOptions'];
    }

    private get defaultTabRange(): PuiDateBottomBarRange {
        return {
            start: startOfMonth(this.END_OF_DAY).toISOString(),
            end: endOfMonth(this.END_OF_DAY).toISOString()
        };
    }

    private get calendarViewConfiguration(): CalendarViewConfiguration {
        return {
            range: {
                start: new Date(this.range.start ?? new Date()),
                end: new Date(this.range.end ?? new Date()),
            },
            items: this.calendarItems,
        }
    }

    private get shouldDisplayDataCompleteCard(): boolean {
        return this.completenessCheckCount.yellowCount === 0 && this.completenessCheckCount.redCount === 0;
    }

    private get shouldDisplayNoDataCard(): boolean {
        return this.completenessCheckCount.greenCount === 0 && this.shouldDisplayDataCompleteCard;
    }

    private get isRangeValid(): boolean {
        if (!this.range.start || !this.range.end) {
            return false;
        }

        return (differenceInDays(new Date(this.range.end), new Date(this.range.start)) + 1) <= this.MAX_ONE_MONTH_IN_DAYS;
    }

    private get selectedSubprocess(): number {
        return this.TAB_INDEX_COMPLETENESS_CHECK_MAP[this.currentTabIndex];
    }

    private get localTimeRange(): PuiDateBottomBarRange {
        return {
            start: format(new Date(this.range.start ?? '') ?? new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
            end: format(new Date(this.range.end ?? '') ?? new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'"),
        };
    }

    private mounted(): void {
        this.setDateSelectorToDefault();
        this.fetchFilters();
    }

    private beforeDestroy(): void {
        this.abortController.abort();
    }

    private unitClicked(payload: CalendarUnitClickPayload): void {
        this.$refs[REF_CONSTANTS.COMPLETENESS_CHECK_DETAILS_MODAL].open({
            unitName: payload.unitName,
            unit: payload.unitSid,
            fromDate: formatISO(new Date(payload.day), { representation: 'complete' }),
            subProcessType: this.selectedSubprocess,
        });
    }

    private hasSameSelectedOptions(appliedValues: number[], selectedOptions: number[]): boolean {
        return appliedValues.every(item => selectedOptions.includes(item))
            && selectedOptions.every(item => appliedValues.includes(item));
    }

    private filtersChanged(appliedValues: PuiFilterAppliedValues): void {
        const hasSameSelectedCountries = this.hasSameSelectedOptions(appliedValues['country'], this.selectedCountries);
        const hasSameSelectedUnits = this.hasSameSelectedOptions(appliedValues['unit'], this.selectedUnits);

        this.selectedCountries = appliedValues['country'];
        this.selectedUnits = appliedValues['unit'];

        if (!hasSameSelectedCountries || !hasSameSelectedUnits) {
            this.reconfigureFilters();
        }

        this.fetchCompletenessCheck();
    }

    @Watch('range', { deep: true })
    private rangeChanged(): void {
        this.fetchCompletenessCheck();
    }

    private tabChanged(newTabIndex: number): void {
        this.currentTabIndex = newTabIndex;

        this.fetchCompletenessCheck();
    }

    private setDateSelectorToDefault(): void {
        this.range.start = this.defaultTabRange.start;
        this.range.end = this.defaultTabRange.end;
    }

    private reconfigureFilters(): void {
        let countryOptions = this.filterOptions.map(e => ({
            displayName: e.countryName,
            value: e.countryId,
        }));
        const allCountries = this.filterOptions.map(e => e.countryId);
        const currentCountries = this.selectedCountries;
        const filterCountries = currentCountries.length !== 0 ? currentCountries : allCountries;

        let unitOptions: UnitFilterOption[] = [];

        if (filterCountries.length !== 0) {
            unitOptions = this.filterOptions
                .filter(e => filterCountries.includes(e.countryId))
                .flatMap(e => e.units.map(unit => ({
                    displayName: unit.unitName,
                    value: unit.unitSid,
                    countryId: e.countryId,
                })));
        }

        const currentUnits = this.selectedUnits.filter(unitSid => unitOptions.map(option => option.value).includes(unitSid));

        if(currentUnits.length !== 0) {
            const filteredUnitOptions: UnitFilterOption[] = unitOptions.filter(unit => currentUnits.includes(unit.value));
            countryOptions = countryOptions.filter((country) => {
                return filteredUnitOptions.some((unit: UnitFilterOption) => {
                    return unit.countryId === country.value;
                });
            });
        }

        this.filterConfiguration = {
            filters: [
                {
                    name: 'country',
                    displayName: this.$t('country'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: false,
                        searchInputPlaceholder: '',
                        options: countryOptions,
                    },
                    selectedValues: {
                        options: currentCountries,
                    },
                    appliedValues: {
                        options: currentCountries,
                    }
                },
                {
                    name: 'unit',
                    displayName: this.$t('unit'),
                    type: 'multiselect',
                    isExpandable: true,
                    isExpanded: true,
                    config: {
                        hasSearchInput: true,
                        searchInputPlaceholder: this.$t('pleaseType'),
                        options: unitOptions,
                    },
                    selectedValues: {
                        options: currentUnits,
                    },
                    appliedValues: {
                        options: currentUnits,
                    }
                }
            ]
        };
    }

    private async fetchFilters(): Promise<void> {
        await this.$store.dispatch('completeness/fetchFilterOptions');
        this.reconfigureFilters();
    }

    private fetchCompletenessCheck(): void {
        if (this.localTimeRange.start && this.localTimeRange.end && this.isRangeValid) {
            this.abortController.abort();
            this.abortController = new AbortController();

            this.completenessPromise = this.$store.dispatch('completeness/fetchItems', {
                fromDate: formatISO(new Date(this.localTimeRange.start), { representation: 'complete' }),
                toDate: this.localTimeRange.end,
                subProcess: this.selectedSubprocess,
                countries: this.selectedCountries.map(e => e.toString()),
                units: this.selectedUnits,
                signal: this.abortController.signal,
            } as PayloadWithSignal<CompletenessCheckParams>);
        }
    }

    private getLegendLabelForProcessStatus(status: number): string {
        switch (status) {
        case RED_STATUS:
            return `${this.$t('red')}: ${this.completenessCheckCount.redCount}`;
        case AMBER_STATUS:
            return `${this.$t('yellow')}: ${this.completenessCheckCount.yellowCount}`;
        case GREEN_STATUS:
            return `${this.$t('green')}: ${this.completenessCheckCount.greenCount}`;
        default:
            return '';
        }
    }
}
