import { Api } from '@coode/fe-sdk/dist/networking';
import Vue from 'vue';

export default class BaseService {
    protected resourcePath = '';

    constructor(resourcePath?: string) {
        this.resourcePath = resourcePath ?? '';
    }

    protected get api(): Api {
        return Vue.prototype.$sdk.$http?.$generic.request.api;
    }
}
