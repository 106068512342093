










































































import Component from 'vue-class-component';
import Vue from 'vue';
import { EmailSettingsItem } from '@/models/interfaces';
import { AMBER_STATUS, RED_STATUS } from '@/config/process-statuses';
import { PROCESSES, SUB_PROCESSES } from '@/config/processes';
import EditEmailTemplateModal
    from '@/components/settings/email-settings-tab/edit-email-template-modal/edit-email-template-modal.vue';
import { EmailTemplateSectionIdentifier } from '@/store/modules/settings.module';

const REF_CONSTANTS = {
    EDIT_EMAIL_TEMPLATE_MODAL: 'editEmailTemplateModal',
} as const;

@Component({
    components: {
        EditEmailTemplateModal,
    },
})
export default class EmailSettingsTabComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;
    private readonly PROCESSES = PROCESSES;
    private readonly SUB_PROCESSES = SUB_PROCESSES;
    private readonly STATUS_SETTINGS_CONFIG = {
        [AMBER_STATUS]: {
            label: 'emailSettingsTab.yellowTemplates',
        },
        [RED_STATUS]: {
            label: 'emailSettingsTab.redTemplates',
        },
    } as const;

    $refs!: {
        [REF_CONSTANTS.EDIT_EMAIL_TEMPLATE_MODAL]: EditEmailTemplateModal;
    };

    private abortController = new AbortController();
    private emailSettingsPromise: Promise<void> | null = null;

    private get emailSettings(): EmailSettingsItem[] {
        return this.$store.getters['settings/getEmailSettings'];
    }

    private get openedEmailSection(): EmailTemplateSectionIdentifier {
        return this.$store.getters['settings/getOpenedEmailTemplateSection'];
    }

    created(): void {
        this.fetchEmailSettings();
    }

    beforeDestroy(): void {
        this.abortController.abort();
    }

    private fetchEmailSettings(): void {
        this.abortController.abort();
        this.abortController = new AbortController();

        this.emailSettingsPromise = this.$store.dispatch('settings/fetchEmailSettings', this.abortController.signal)
            .then(() => this.$store.dispatch('settings/fetchEmailTags', this.abortController.signal))
            .catch(() => {
                (this as any).$pui.toast({
                    type: 'error',
                    title: this.$t('apiError.errorGettingData'),
                    copy: this.$t('apiError.couldNotGetEmailSettings')
                });
            });
    }

    private getEmailSettings(status: string | number, process: string | number): EmailSettingsItem[] {
        return this.emailSettings.filter(e => e.color === Number(status) && e.process === Number(process));
    }

    private checkEmptyForEmail(value: string): string {
        return !value ? this.$t('emailSettingsTab.notConfigured') : value;
    }

    private openEditEmailTemplateModal(emailSettingsItem?: EmailSettingsItem): void {
        this.$refs[REF_CONSTANTS.EDIT_EMAIL_TEMPLATE_MODAL].openEmailTemplate(emailSettingsItem);
    }
}
