
















import { Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utils/eventbus';
import './snackbar.less';

@Component({
    name: 'snackbar',
})
export default class Snackbar extends Vue {
    private message = '';
    private duration = 5000;
    private isVisible = false;
    private timeout: any;

    private created(): void {
        EventBus.$on(EventBus.GLOBAL.SHOW_SNACKBAR, (message: string, overrideDefaultDuration: number | null) => {
            if (overrideDefaultDuration) {
                this.duration = overrideDefaultDuration;
            }
            this.message = message;
            this.isVisible = true;
            this.timeout = setTimeout(() => {
                this.isVisible = false;
            }, this.duration);
        });
    }

    private dismiss(): void {
        this.isVisible = false;
        clearTimeout(this.timeout);
    }
}

