import { render, staticRenderFns } from "./comments-lightbox.vue?vue&type=template&id=806730ac"
import script from "./comments-lightbox.vue?vue&type=script&lang=ts"
export * from "./comments-lightbox.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports