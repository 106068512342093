import {
    PUICOLOR_SEVERITY_10,
    PUICOLOR_SEVERITY_20,
    PUICOLOR_SEVERITY_60,
    PUICOLOR_WARM_GREY
} from '@enerlytics/pebble-ui/dist/constants/colors.js';

interface ProcessStatus {
    [key: string]: {
        color: string;
        status: string;
        statusColorName: string;
    };
}

export const RED_STATUS = 2;
export const AMBER_STATUS = 3;
export const GREEN_STATUS = 1;
export const GREY_STATUS = 4;

export const PROCESS_STATUS = {
    [RED_STATUS]: {
        color: PUICOLOR_SEVERITY_10,
        status: 'statusRed',
        statusColorName: 'red'
    },
    [AMBER_STATUS]: {
        color: PUICOLOR_SEVERITY_20,
        status: 'statusAmber',
        statusColorName: 'yellow'
    },
    [GREEN_STATUS]: {
        color: PUICOLOR_SEVERITY_60,
        status: 'statusGreen',
        statusColorName: 'green'
    },
    [GREY_STATUS]: {
        color: PUICOLOR_WARM_GREY,
        status: 'statusGrey',
        statusColorName: 'grey'
    }
} as ProcessStatus;
