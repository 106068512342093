

































import Component from 'vue-class-component';
import Vue from 'vue';
import { PuiLightbox } from '@/models/pebble-ui';
import CompletenessCheckModalDetailsTab
    from '@/components/completeness-check/completeness-check-details-modal/completeness-check-modal-details-tab/completeness-check-modal-details-tab.vue';
import CompletenessCheckModalCommentsTab
    from '@/components/completeness-check/completeness-check-details-modal/completeness-check-modal-comments-tab/completeness-check-modal-comments-tab.vue';
import { GetCompletenessCheckDetailItemPayload } from '@/models/interfaces';
import { format } from 'date-fns';
import { SUB_PROCESSES } from '@/config/processes';

type CompletenessCheckDetailsModalInitParams = GetCompletenessCheckDetailItemPayload & { unitName: string };

const REF_CONSTANTS = {
    LIGHTBOX: 'lightbox',
} as const;

@Component({
    components: {
        CompletenessCheckModalDetailsTab,
        CompletenessCheckModalCommentsTab,
    },
})
export default class CompletenessCheckDetailsModalComponent extends Vue {
    private readonly REF_CONSTANTS = REF_CONSTANTS;

    private unitName = '';
    private completenessDetailsPayload: GetCompletenessCheckDetailItemPayload | null = null;

    private abortController = new AbortController();
    private completenessCheckDetailsPromise: Promise<void> | null = null;

    $refs!: {
        [REF_CONSTANTS.LIGHTBOX]: PuiLightbox;
    };

    public open({ unitName, ...payload }: CompletenessCheckDetailsModalInitParams): void {
        this.unitName = unitName;
        this.completenessDetailsPayload = payload;
        this.$refs[REF_CONSTANTS.LIGHTBOX].open();

        this.completenessCheckDetailsPromise = this.$store.dispatch('completeness/fetchDetailItems', {
            signal: this.abortController.signal,
            ...payload,
        }).catch(() => {
            this.$refs[REF_CONSTANTS.LIGHTBOX].close();
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('apiError.errorGettingData'),
                copy: this.$t('apiError.couldNotGetDetailItems')
            });
        });
    }

    private get completenessDetails(): string {
        const { fromDate, subProcessType } = this.completenessDetailsPayload as GetCompletenessCheckDetailItemPayload;
        const date = format(new Date(fromDate), 'dd/MM/yyyy');
        const subProcessLabel = this.$t(SUB_PROCESSES[subProcessType].label);
        return `[${date} / ${subProcessLabel}]`;
    }
}
