import BaseService from '@/utils/base-service';
import { PostCommentPayload } from '@/models/interfaces';

/**
 * @name CommentService
 * @description This class is used to communicate with the GDC service for
 * information related to comments.
 */
export class CommentService extends BaseService {
    constructor() {
        super('comment');
    }

    public async postComment(payload: PostCommentPayload, signal?: AbortSignal): Promise<void> {
        await this.api.post(this.resourcePath, payload, { signal });
    }
}
