import Component from 'vue-class-component';
import Vue from 'vue';
import { Permission } from '@/config/permissions';

@Component
export class AccessControlled extends Vue {
    public get userPermissions(): Permission[] {
        return this.$store.getters['user/getUserPermissions'];
    }

    public hasPermission(permission: Permission): boolean {
        return this.userPermissions.includes(permission);
    }

    public hasSomePermission(...permissions: Permission[]): boolean {
        return permissions.some(permission => this.hasPermission(permission));
    }

    public hasAllPermissions(...permissions: Permission[]): boolean {
        return permissions.every(permission => this.hasPermission(permission));
    }
}
