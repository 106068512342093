import { AddThresholdPayload, EditThresholdPayload, GetThresholdsSubprocessItem } from '@/models/services/thresholds';
import { Module } from 'vuex';
import { PayloadWithSignal } from '@/models/interfaces';
import { ThresholdsService } from '@/services/thresholds-service';

const thresholdsService = new ThresholdsService();

interface ThresholdsState {
    thresholds: GetThresholdsSubprocessItem[];
}

const initialState: ThresholdsState = {
    thresholds: [],
};

const SET_THRESHOLDS = 'setThresholds';
const RESET_THRESHOLDS = 'resetThresholds';

const ThresholdsModule: Module<ThresholdsState, any> = {
    state: initialState,

    mutations: {
        [SET_THRESHOLDS](state, thresholds: GetThresholdsSubprocessItem[]): void {
            state.thresholds = thresholds;
        },
        [RESET_THRESHOLDS](state): void {
            state.thresholds = [];
        },
    },

    actions: {
        async fetchThresholds({ commit }, { signal }: PayloadWithSignal): Promise<void> {
            try {
                const thresholds = await thresholdsService.getThresholds(signal);
                commit(SET_THRESHOLDS, thresholds.result.thresholdData);
            } catch (e) {
                commit(RESET_THRESHOLDS);

                if (!signal.aborted) {
                    throw e;
                }
            }
        },
        async addThreshold(context, payload: AddThresholdPayload): Promise<void> {
            await thresholdsService.addThreshold(payload);
        },
        async editThreshold(context, payload: EditThresholdPayload): Promise<void> {
            await thresholdsService.editThreshold(payload);
        }
    },

    getters: {
        getThresholds(state): GetThresholdsSubprocessItem[] {
            return state.thresholds;
        },
    },

    namespaced: true,
};

export default ThresholdsModule;
