



























































import Component from 'vue-class-component';
import Vue from 'vue';
import {
    CompletenessCheckDetailItem,
    CompletenessCheckDetailItemHourly,
    GetCompletenessCheckDetailItemPayload
} from '@/models/interfaces';
import CompletenessCheckModalDetailsTableCell
    from '@/components/completeness-check/completeness-check-details-modal/completeness-check-modal-details-tab/completeness-check-modal-details-table-cell/completeness-check-modal-details-table-cell.vue';
import TableAttachment from '@/components/ui/table/table-attachment.vue';
import TableSearch from '@/components/ui/table/table-search.vue';
import TablePagination, { TablePaginationDefaults } from '@/components/ui/table/table-pagination.vue';
import { Prop } from 'vue-property-decorator';

type TableDataRow = {
    [key: string]: CompletenessCheckDetailItemHourly | string;
};

@Component({
    components: {
        TablePagination,
        TableSearch,
        TableAttachment,
        CompletenessCheckModalDetailsTableCell,
    },
})
export default class CompletenessCheckModalDetailsTabComponent extends Vue {
    private readonly HOURS_IN_DAY = 24;

    /*
     * This prop is used to know the payload for this specific Completeness Check details query.
     */
    @Prop({ required: true })
    private completenessDetailPayload!: GetCompletenessCheckDetailItemPayload;

    private abortController = new AbortController();
    private completenessCheckDetailsPromise: Promise<void> | null = Promise.resolve();

    private filterByKeyword: string | null = null;
    private pageNumber = TablePaginationDefaults.INITIAL_PAGE_NUMBER;
    private pageSize = TablePaginationDefaults.INITIAL_PAGE_SIZE;

    private get completenessCheckDetailItems(): CompletenessCheckDetailItem[] {
        return this.$store.getters['completeness/getFilteredItems'];
    }

    private get completenessCheckFilteredItemsCount(): number {
        return this.$store.getters['completeness/getFilteredItemsCount'];
    }

    private get tableData(): TableDataRow[] {
        const data: TableDataRow[] = [];

        this.completenessCheckDetailItems.forEach(item => {
            const tableItem = {
                valueIdentification: item.valueIdentification,
            };

            item.hourlies.forEach(hourItem => {
                tableItem[hourItem.hour.toString()] = hourItem;
            });

            data.push(tableItem);
        });

        return data;
    }

    private get hoursInDayStrings(): string[] {
        return [...Array(this.HOURS_IN_DAY).keys()].map(e => e.toString());
    }

    private get columns(): string[] {
        return ['valueIdentification', ...this.hoursInDayStrings];
    }

    private get headings(): Record<string, string> {
        const headings = {
            valueIdentification: this.$t('valueIdentification'),
        };

        this.hoursInDayStrings.forEach(e => {
            headings[e] = e;
        });

        return headings;
    }

    private filterByKeywordChanged(keyword: string): void {
        this.pageNumber = 1;
        this.filterByKeyword = keyword;
        this.tableFiltersChanged();
    }

    private pageSizeChanged(pageSize: number): void {
        if (this.pageSize === pageSize) {
            return;
        }

        if (TablePaginationDefaults.PAGE_SIZE_SELECTION_OPTIONS.includes(pageSize)) {
            this.pageNumber = 1;
            this.pageSize = pageSize;
            this.tableFiltersChanged();
        }
    }

    private pageNumberChanged(pageNumber: number): void {
        if (this.pageNumber === pageNumber) {
            return;
        }

        this.pageNumber = pageNumber;
        this.tableFiltersChanged();
    }

    private tableFiltersChanged(): void {
        this.abortController.abort();
        this.abortController = new AbortController();

        this.completenessCheckDetailsPromise = this.$store.dispatch('completeness/fetchFilteredDetailItems', {
            signal: this.abortController.signal,
            valueIdSearchText: this.filterByKeyword,
            itemsPerPage: this.pageSize,
            pageNo: this.pageNumber,
            ...this.completenessDetailPayload,
        }).catch(() => {
            (this as any).$pui.toast({
                type: 'error',
                title: this.$t('apiError.errorGettingData'),
                copy: this.$t('apiError.couldNotGetDetailItems')
            });
        });
    }
}
