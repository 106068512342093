import BaseService from '@/utils/base-service';
import { ApiResponse } from '@/models';
import { GetEmailSettingsResponse, GetEmailTagsResponse } from '@/models/services/email-settings';
import {
    AddOrUpdateEmailCommentTemplatePayload,
    AddOrUpdateEmailTemplatePayload,
    EmailTemplateItem,
    GetEmailTemplatePayload
} from '@/models/interfaces';

/**
 * @name EmailSettingsService
 * @description This class is used to communicate with the GDC service for
 * information related to Email Settings.
 */
export class EmailSettingsService extends BaseService {
    constructor() {
        super('email-settings');
    }

    public async fetchEmailSettings(signal?: AbortSignal): Promise<ApiResponse<GetEmailSettingsResponse>> {
        return (await this.api.get<ApiResponse<GetEmailSettingsResponse>>(`${this.resourcePath}/list`, { signal })).data;
    }

    public async fetchEmailTags(signal?: AbortSignal): Promise<ApiResponse<GetEmailTagsResponse>> {
        return (await this.api.get<ApiResponse<GetEmailTagsResponse>>(`${this.resourcePath}/tags`, { signal })).data;
    }

    public async fetchEmailTemplate(payload: GetEmailTemplatePayload, signal?: AbortSignal): Promise<ApiResponse<EmailTemplateItem>> {
        return (await this.api.post<ApiResponse<EmailTemplateItem>>(`${this.resourcePath}/view`, payload, { signal })).data;
    }

    public async fetchEmailCommentsTemplate(signal?: AbortSignal): Promise<ApiResponse<EmailTemplateItem>> {
        return (await this.api.get<ApiResponse<EmailTemplateItem>>(`${this.resourcePath}/comment`, { signal })).data;
    }

    public async updateEmailTemplate(payload: AddOrUpdateEmailTemplatePayload): Promise<void> {
        await this.api.post(`${this.resourcePath}/add-update`, payload);
    }

    public async updateEmailCommentsTemplate(payload: AddOrUpdateEmailCommentTemplatePayload): Promise<void> {
        await this.api.post(`${this.resourcePath}/updateComment`, payload);
    }
}

