import BaseService from '@/utils/base-service';
import { ApiResponse } from '@/models';
import { CompletenessCheckDetailsItemResponse } from '@/models/services/completeness-check-details';
import { GetCompletenessCheckDetailItemPayload } from '@/models/interfaces';

/**
 * @name CompletenessCheckDetailsService
 * @description This class is used to communicate with the GDC service for
 * information related to Completeness Check.
 */
export class CompletenessCheckDetailsService extends BaseService {
    constructor() {
        super('completeness-check-details');
    }

    public async fetchCompletenessCheckDetailsItems(payload: GetCompletenessCheckDetailItemPayload, signal?: AbortSignal): Promise<ApiResponse<CompletenessCheckDetailsItemResponse>> {
        return (await this.api.post<ApiResponse<CompletenessCheckDetailsItemResponse>>(`${this.resourcePath}/details`, payload, { signal })).data;
    }
}
