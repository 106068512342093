import { Module } from 'vuex';
import { CachedUser, PostCommentPayload } from '@/models/interfaces';
import { UamService } from '@/services/uam-service';
import { UamUserSearchItem } from '@/models/services/uam';
import { CommentService } from '@/services/comment-service';

const commentService = new CommentService();
const uamService = new UamService();

const state: CachedUserState = {
    cachedUsers: [],
};

interface CachedUserState {
    cachedUsers: CachedUser[];
}

const SET_CACHED_USERS = 'setCachedUsers';

const CommentsModule: Module<CachedUserState, any> = {
    state,

    mutations: {
        [SET_CACHED_USERS](state, user: CachedUser): void {
            state.cachedUsers.push(user);
        },
    },

    actions: {
        async postComment(context, { params, signal }: { params: PostCommentPayload; signal?: AbortSignal }): Promise<void> {
            try {
                await commentService.postComment(params, signal);
            } catch(e) {
                if (!signal?.aborted) {
                    throw e;
                }
            }
        },
        async getUserByKid({ state, commit }, { userKid, signal }: { userKid: string; signal?: AbortSignal }): Promise<CachedUser | undefined> {
            const cachedUser = state.cachedUsers.find(e => e.userKid === userKid);
            if (cachedUser) {
                return cachedUser;
            }

            try {
                const response = await uamService.getUserByKid(userKid, signal);
                const user: CachedUser = {
                    name: `${response.result.name} (${userKid})`,
                    userKid
                };

                commit(SET_CACHED_USERS, user);
                return user;
            } catch (e) {
                if (!signal?.aborted) {
                    throw e;
                }
            }
        },
        async searchUsers(context, { searchQuery, signal }: { searchQuery: string; signal?: AbortSignal }): Promise<UamUserSearchItem[] | undefined> {
            try {
                const response = await uamService.getUsers(searchQuery, signal);
                return response.result.items;
            } catch(e) {
                if (!signal?.aborted) {
                    throw e;
                }
            }
        },
    },

    getters: {
        getCachedUsers(state): CachedUser[] {
            return state.cachedUsers;
        },
    },

    namespaced: true
}

export default CommentsModule;
